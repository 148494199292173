import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { CustomTestType } from 'types/testType';
import { Margin, TextCase } from 'types/theme/text';

import { TextType } from '../types';

type CaptionSmallProps = CustomTestType & TextType;

export const StyledCaption = styled.span<
  Pick<CaptionSmallProps, 'disabled' | 'textCase' | 'textColor' | 'marginBottom'>
>(({ theme: { palette, typography, spacing }, textCase, textColor, marginBottom, disabled }) => {
  const notDisabledTextColor = textColor ? palette.text[textColor] : palette.text.secondary;

  return css`
    ${typography.subtitle};
    text-transform: ${textCase ?? TextCase.None};
    color: ${disabled ? palette.text.disabled : notDisabledTextColor};
    ${marginBottom &&
    css`
      display: block;
      margin: 0 0 ${spacing(marginBottom ?? Margin.Zero)};
    `}
  `;
});

const CaptionSmall = ({ children, disabled, textCase, textColor, marginBottom, dataTestId }: CaptionSmallProps) => (
  <StyledCaption
    disabled={disabled}
    textCase={textCase}
    textColor={textColor}
    marginBottom={marginBottom}
    data-testid={dataTestId}
  >
    {children}
  </StyledCaption>
);

export default CaptionSmall;
