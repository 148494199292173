import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const publicFinancingRequestQuery = loader(
  '../../graphql/queries/publicFinancingRequest/publicFinancingRequest.graphql',
);

type Props = {
  variables: any;
  skip: boolean;
};

const usePublicFinancingRequestQuery = ({ variables, skip = false }: Props) => {
  const { data, loading } = useQuery(publicFinancingRequestQuery, {
    fetchPolicy: 'cache-first',
    variables,
    skip,
  });

  return {
    data: data?.publicFinancingRequest,
    loading,
  };
};

export default usePublicFinancingRequestQuery;
