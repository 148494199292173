import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const createERCFormV2Mutation = loader('../../../graphql/mutations/erc/createERCFormV2.graphql');

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};
// TODO type props, type return result
const useCreateERCFormMutation = ({ onCompleted, onError }: Props) => {
  const [createERCForm, mutationResult] = useMutation(createERCFormV2Mutation, {
    onCompleted: (data) => onCompleted(data?.createErcFormV2 ?? {}),
    onError: (error) => onError(error),
  });

  return [createERCForm, mutationResult] as MutationTuple<any, any>;
};

export default useCreateERCFormMutation;
