import ThemeProvider from 'contexts/ThemeContext';
import { NotifierProvider } from 'contexts/NotifierContext';

import DefaultTemplate from 'components/templates/DefaultTemplate';

import Header from './components/Header';
import Form from './components/Form';

const OneStep = () => {
  return (
    <ThemeProvider>
      <NotifierProvider>
        <DefaultTemplate>
          <Header />
          <Form />
        </DefaultTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default OneStep;
