import { formatToFormErrors } from './errors';

export const isValidForm = (errors = {}) => Object.keys(errors).length === 0;

export const runValidation = (schema, values) => {
  try {
    schema?.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return formatToFormErrors(error);
  }
};
