import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import styled from '@emotion/styled';

import { NumberFormatProps } from './types';

const StyledText = styled.span`
  white-space: nowrap;
`;

const getText = (value: string) => <StyledText>{value}</StyledText>;

const CustomNumberFormat = forwardRef(({ inputRef = null, renderText, ...other }: NumberFormatProps, ref) => {
  return <NumberFormat {...other} getInputRef={inputRef || ref} renderText={renderText ? getText : undefined} />;
});

export default CustomNumberFormat;
