import {
  YUP_NULLABLE_REQUIRED,
  YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  YUP_REQUIRED_PHONE_NUMBER,
  YUP_DATE_REQUIRED_PAST,
  YUP_REQUIRED_TAX_ID,
  YUP_REQUIRED_SSN,
  YUP_REQUIRED_ADDRESS_LINE,
} from 'config/constants/yup';
import { YUP_NULLABLE_REQUIRED_ZIP } from 'config/yup/zipCode';

import DateInput from 'components/ui/atoms/forms/DateInput';
import SSNInput from 'components/ui/atoms/forms/SSNInput';
import EnumSelect from 'components/ui/atoms/forms/EnumSelect';
import ZipInput from 'components/ui/atoms/forms/ZipInput';
import TaxIdInput from 'components/ui/atoms/forms/TaxIdInput';
import EmailInput from 'components/ui/atoms/forms/EmailInput';
import PhoneInput from 'components/ui/atoms/forms/PhoneInput';
import StringInput from 'components/ui/atoms/forms/StringInput';

enum ContactInformationFormFields {
  MerchantFirstName = 'merchantFirstName',
  MerchantLastName = 'merchantLastName',
  MerchantDateOfBirth = 'merchantDateOfBirth',
  MerchantSsn = 'merchantSsn',
  MerchantAddressLine1 = 'merchantAddressLine1',
  MerchantCity = 'merchantCity',
  MerchantState = 'merchantState',
  MerchantZipCode = 'merchantZipCode',
  MerchantEmail = 'merchantEmail',
  MerchantCellPhone = 'merchantCellPhone',
  BusinessName = 'businessName',
  BusinessStartedAt = 'businessStartedAt',
  BusinessFederalTaxId = 'businessFederalTaxId',
  Documents = 'documents',
}

export const getContactInformationFields = (isMobile: boolean) => [
  {
    id: 'first-name',
    name: ContactInformationFormFields.MerchantFirstName,
    label: 'First Name',
    placeholder: 'Enter first name',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 4,
  },
  {
    id: 'last-name',
    name: ContactInformationFormFields.MerchantLastName,
    label: 'Last Name',
    placeholder: 'Enter last name',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 4,
  },
  {
    id: 'business-name',
    name: ContactInformationFormFields.BusinessName,
    label: 'Legal Company Name',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'date-of-birth',
    name: ContactInformationFormFields.MerchantDateOfBirth,
    label: 'Date of Birth',
    control: <DateInput />,
    disableFuture: true,
    required: true,
  },
  {
    id: 'business-start-date',
    name: ContactInformationFormFields.BusinessStartedAt,
    label: 'Business Start Date',
    control: <DateInput />,
    disableFuture: true,
    required: true,
  },
  {
    id: 'ssn',
    name: ContactInformationFormFields.MerchantSsn,
    label: 'Social Security Number',
    control: <SSNInput />,
    required: true,
  },
  {
    id: 'tax-id',
    name: ContactInformationFormFields.BusinessFederalTaxId,
    label: 'EIN#/TAX ID',
    control: <TaxIdInput />,
    required: true,
  },
  {
    id: 'home-address-line-1',
    name: ContactInformationFormFields.MerchantAddressLine1,
    label: 'Home Address Line 1',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'email',
    name: ContactInformationFormFields.MerchantEmail,
    label: 'Email',
    control: <EmailInput />,
    required: true,
  },
  {
    id: 'home-city',
    name: ContactInformationFormFields.MerchantCity,
    label: 'Home City',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 6,
  },
  {
    id: 'home-state',
    name: ContactInformationFormFields.MerchantState,
    label: 'Home State',
    control: <EnumSelect enumName="State" />,
    required: true,
    columns: isMobile ? 1 : 6,
  },
  {
    id: 'home-zip',
    name: ContactInformationFormFields.MerchantZipCode,
    label: 'Home Zip',
    // @ts-expect-error ignore required fields
    control: <ZipInput />,
    required: true,
    columns: isMobile ? 1 : 6,
  },
  {
    id: 'phone-number',
    name: ContactInformationFormFields.MerchantCellPhone,
    label: 'Phone Number',
    control: <PhoneInput />,
    required: true,
  },
];

export const DEFAULT_VALUES = {
  [ContactInformationFormFields.MerchantFirstName]: null,
  [ContactInformationFormFields.MerchantLastName]: null,
  [ContactInformationFormFields.BusinessName]: null,
  [ContactInformationFormFields.MerchantDateOfBirth]: null,
  [ContactInformationFormFields.BusinessStartedAt]: null,
  [ContactInformationFormFields.MerchantSsn]: null,
  [ContactInformationFormFields.BusinessFederalTaxId]: null,
  [ContactInformationFormFields.MerchantAddressLine1]: null,
  [ContactInformationFormFields.MerchantEmail]: null,
  [ContactInformationFormFields.MerchantCity]: null,
  [ContactInformationFormFields.MerchantState]: '',
  [ContactInformationFormFields.MerchantZipCode]: null,
  [ContactInformationFormFields.MerchantCellPhone]: null,
  [ContactInformationFormFields.Documents]: null,
};

export const requiredValidationFields = {
  [ContactInformationFormFields.MerchantFirstName]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.MerchantLastName]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.BusinessName]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.MerchantDateOfBirth]: YUP_DATE_REQUIRED_PAST,
  [ContactInformationFormFields.BusinessStartedAt]: YUP_DATE_REQUIRED_PAST,
  [ContactInformationFormFields.MerchantSsn]: YUP_REQUIRED_SSN,
  [ContactInformationFormFields.BusinessFederalTaxId]: YUP_REQUIRED_TAX_ID,
  [ContactInformationFormFields.MerchantAddressLine1]: YUP_REQUIRED_ADDRESS_LINE,
  [ContactInformationFormFields.MerchantEmail]: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  [ContactInformationFormFields.MerchantCity]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.MerchantState]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.MerchantZipCode]: YUP_NULLABLE_REQUIRED_ZIP,
  [ContactInformationFormFields.MerchantCellPhone]: YUP_REQUIRED_PHONE_NUMBER,
};
