import FormWithValidation, { SubmitHandler } from 'components/ui/molecules/FormWithValidation';
import type { FormFooterProps } from 'components/ui/molecules/FormWithValidation/types';

import { DEFAULT_VALUES, fields as formFields, requiredValidationFields } from './config';
import { FormSectionMediator } from '../../../ERC/components/form/formSection';
import { FormSubmitButton } from '../../styled';
import { BusinessDetailsFormProps } from './types';
import useUpdateIntakeFormBusinessDetails from './useUpdateIntakeFormBusinessDetails';
import useIntakeFormValues from '../../hooks/useIntakeFormValues';

const FormFooter = ({ submitHandler, disabled, loading }: FormFooterProps) => (
  <FormSubmitButton onClick={submitHandler} disabled={disabled} loading={loading} variant="contained" disableElevation>
    Next
  </FormSubmitButton>
);

const BusinessDetailsForm = ({ isMobile }: BusinessDetailsFormProps) => {
  const { data, loading: dataLoading } = useIntakeFormValues(DEFAULT_VALUES);
  const { updateIntakeFormBusinessDetails, loading } = useUpdateIntakeFormBusinessDetails();

  const handleSubmit: SubmitHandler = async (fields) => {
    await updateIntakeFormBusinessDetails({ fields });
  };

  const formConfig = {
    id: 'updateLeadBusinessDetails',
    columns: isMobile ? 1 : 2,
    withHiddenFields: false,
    formFields,
  };

  return (
    <FormWithValidation
      id="apply-business-details-form"
      loading={loading || dataLoading}
      defaultValues={data}
      requiredValidationFields={requiredValidationFields}
      submitHandler={handleSubmit}
      FormFooter={FormFooter}
    >
      <FormSectionMediator key={formConfig.id} {...formConfig} />
    </FormWithValidation>
  );
};

export default BusinessDetailsForm;
