import { styled as muiStyled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';

const StyledHelperText = muiStyled(FormHelperText)(({ theme: { typography, palette, spacing } }) => ({
  ...typography.t3,
  display: 'flex',
  justifyContent: 'space-between',
  color: palette.text.secondary,
  margin: 0,
  marginTop: spacing(0.375),

  '&.Mui-disabled': {
    color: palette.text.disabled,
  },
}));

export default StyledHelperText;
