import { useMemo, useState, Children, ChangeEvent, ReactElement, cloneElement, useEffect } from 'react';
import * as Yup from 'yup';
import styled from '@emotion/styled';

import useValidation from 'lib/hooks/validation/useValidation';

import Button from 'components/ui/atoms/buttons/Button';

import type { FormContainerProps } from './types';
import Loader from '../Loader';
import { FormFooterProps } from './types';

const Form = styled.form`
  width: 100%;
  position: relative;
  margin-top: 40px;
`;

const ButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DefaultFormFooter = ({ disabled, submitHandler }: Pick<FormFooterProps, 'disabled' | 'submitHandler'>) => (
  <ButtonBlock>
    <Button
      variant="contained"
      color="primary"
      disableRipple
      disableElevation
      disabled={disabled}
      onClick={submitHandler}
    >
      Submit Application
    </Button>
  </ButtonBlock>
);

const FormWithValidation = ({
  id,
  children,
  defaultValues,
  requiredValidationFields,
  submitHandler,
  resetOnSubmit = false,
  loading,
  FormFooter = DefaultFormFooter,
}: FormContainerProps) => {
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);

  const {
    autoValidation: autoRequiredValidation,
    fields,
    isValid,
    resetFormFields,
    ...formProps
  } = useValidation({ fields: defaultValues }, true);

  const requiredValidationSchema = useMemo(() => Yup.object().shape(requiredValidationFields), [fields]);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (!isFormValuesChanged) setIsFormValuesChanged(true);
    autoRequiredValidation(requiredValidationSchema, name, value);
  };

  const handleSubmit = async () => {
    try {
      if (submitHandler) await submitHandler(fields);
      if (resetOnSubmit) resetFormFields();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    autoRequiredValidation(requiredValidationSchema);
  }, [requiredValidationSchema]);

  return (
    <Form id={id}>
      <div>
        {Children.map(children, (child: ReactElement) =>
          cloneElement(child, { ...formProps, validatedFields: fields, handleChange, isFormValuesChanged }),
        )}
      </div>
      <FormFooter loading={loading} submitHandler={handleSubmit} disabled={!isValid} />
      {loading && <Loader />}
    </Form>
  );
};

export default FormWithValidation;
