import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import {
  CreditCardProcessingFormQueryVariables,
  CreditCardProcessingFormQuery as CreditCardProcessingFormQueryOriginData,
} from './creditCardProcessingForm.generated';

const creditCardProcessingFormQuery = loader('./creditCardProcessingForm.graphql');

type UseCreditCardProcessingFormQueryArgs = {
  variables: CreditCardProcessingFormQueryVariables;
  skip: boolean;
};

type CreditCardProcessingFormData = CreditCardProcessingFormQueryOriginData['creditCardProcessingForm'];

const getData = (data: CreditCardProcessingFormQueryOriginData): CreditCardProcessingFormData =>
  data?.creditCardProcessingForm;

const useCreditCardProcessingFormQuery = ({ variables, skip = false }: UseCreditCardProcessingFormQueryArgs) => {
  const { data, ...rest } = useQuery(creditCardProcessingFormQuery, {
    fetchPolicy: 'cache-first',
    variables,
    skip,
  });

  return {
    data: getData(data),
    ...rest,
  };
};

export default useCreditCardProcessingFormQuery;
