import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const makeFinancingRequestMutation = loader('../../../graphql/mutations/calculator/makeFinancingRequest.graphql');

type Props = {
  onCompleted: (data: any) => void;
};

// TODO type props, type return result
const useMakeFinancingRequestMutation = (options: Props) => {
  const [makeFinancingRequest, mutationResult] = useMutation(makeFinancingRequestMutation, options);

  return [makeFinancingRequest, mutationResult] as MutationTuple<any, any>;
};

export default useMakeFinancingRequestMutation;
