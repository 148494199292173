import { ChangeEvent } from 'react';

import type { Event, Value } from '../types';

export const getCustomEvent = (event: ChangeEvent<{ name: string; value: Value }>): Event => ({
  preventDefault: () => event.preventDefault(),
  target: {
    name: event.target.name,
    value: event.target.value,
  },
});

export default getCustomEvent;
