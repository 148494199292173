import type { Event, Value } from '../types';

export const getCustomEvent = (value: Value, name?: string): Event => ({
  preventDefault: () => {},
  target: {
    name,
    value,
  },
});

export default getCustomEvent;
