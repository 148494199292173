import styled from '@emotion/styled';
import { css } from '@emotion/react';

const DEFAULT_GAP = 3;
const DEFAULT_COLUMNS = 3;
const DEFAULT_MIN_WIDTH = '300px';
const DEFAULT_MAX_WIDTH = '100%';

type FlexTableProps = {
  gap?: number;
};

type FlexContainerProps = {
  gap?: number;
};

type FlexElementProps = {
  columns?: number;
  minWidth?: string;
  maxWidth?: string;
};

export const FlexTable = styled.div<FlexTableProps>(
  ({ gap = DEFAULT_GAP, theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing(gap)};
  `,
);

export const FlexContainer = styled.div<FlexContainerProps>(
  ({ gap = DEFAULT_GAP, theme: { spacing } }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ${spacing(gap)};
  `,
);

export const FlexElement = styled.div<FlexElementProps>(
  ({ columns = DEFAULT_COLUMNS, minWidth = DEFAULT_MIN_WIDTH, maxWidth = DEFAULT_MAX_WIDTH }) => css`
    max-width: ${maxWidth};
    width: ${`calc(100% / ${columns})`};
    flex: 0 0 ${minWidth};
    flex-wrap: wrap;
  `,
);
