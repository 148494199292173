import { convertCentToDollar } from 'lib/money';

import transformToValidValue from './transformToValidValue';
import type { EventValue } from '../types';

const getDisplayedValue = (value: any): string | number => {
  const validValue: EventValue = transformToValidValue(value);

  if (validValue === null) return '';

  const displayedValue = convertCentToDollar(validValue);

  return Number.isNaN(displayedValue) ? '' : displayedValue;
};

export default getDisplayedValue;
