import { css } from '@emotion/react';
import MulishSemiBold from './Mulish-SemiBold.woff2';

export const Mulish = css`
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Mulish SemiBold'), local('Mulish-SemiBold'), url(${MulishSemiBold}) format('woff2');
  }
`;
