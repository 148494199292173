import useFormHiddenFields from 'components/pages/ERC/hook/useFormHiddenFields';
import { FormSectionProps } from 'components/pages/ERC/components/form/types/FormSectionProps';
import FormSection from 'components/shared/organisms/form/FormSection';

const FormSectionWithHiddenFields = ({ validatedFields, handleChange, formFields, ...props }: FormSectionProps) => {
  const { changeHandler, visibleFormFields } = useFormHiddenFields(formFields, handleChange);

  return (
    <FormSection
      formFields={visibleFormFields}
      handleChange={changeHandler}
      validatedFields={validatedFields}
      {...props}
    />
  );
};

export default FormSectionWithHiddenFields;
