import { useSearchParams } from 'react-router-dom';

import { NotifierProvider } from 'contexts/NotifierContext';
import ThemeProvider, { ThemeTypes } from 'contexts/ThemeContext';
import { UUID } from 'config/constants/queryParams';

import NoSpacingTemplate from 'components/templates/NoSpacingTemplate';
import Header from 'components/ui/molecules/Header';
import Notifier from 'components/ui/atoms/Notifier';

import NotFound from '../NotFound';
import CalculatorContainer from './CalculatorContainer';
import { CalculatorContainerWrapper } from './styled';

const Calculator = () => {
  const [searchParams] = useSearchParams();
  const formUuid = searchParams.get(UUID);

  return (
    <ThemeProvider themeType={ThemeTypes.Green}>
      <NotifierProvider>
        <NoSpacingTemplate>
          {formUuid === 'null' || !formUuid ? (
            <NotFound />
          ) : (
            <>
              <CalculatorContainerWrapper>
                <Header />
                <CalculatorContainer uuid={formUuid} />
              </CalculatorContainerWrapper>
              <Notifier />
            </>
          )}
        </NoSpacingTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default Calculator;
