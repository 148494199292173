const Breakpoints = {
  xxs: 0,
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
} as const;

export type BreakpointsKey = keyof typeof Breakpoints;

export default Breakpoints;
