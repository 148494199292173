import { Theme, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

const ChipStyle = ({ theme: { typography, palette, spacing } }: { theme: Theme }) => ({
  ...typography.input,
  borderColor: palette.border,
  margin: spacing(0.3),
  maxWidth: '100%',

  '& .MuiChip-label': {
    cursor: 'pointer',
  },

  '&.MuiChip-colorSecondary': {
    borderColor: palette.error.main,
  },

  '&.MuiChip-outlined.MuiChip-colorSecondary > .MuiChip-deleteIcon': {
    color: palette.error.main,
  },

  '&.MuiChip-deletableColorSecondary': {
    color: palette.error.main,
  },

  '&.Mui-disabled': {
    borderColor: palette.border,
  },
});

const StyledChip = styled(Chip)(ChipStyle);

export default StyledChip;
