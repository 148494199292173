import { useEffect } from 'react';
import { withTheme } from '@emotion/react';

import getScheme from './helpers/getScheme';

import { CloseButton } from '../buttons/IconButton';

import { Wrapper, Container, Text, TextWrapper, ButtonWrapper } from './styled';

const Toast = ({ theme, children, type, action, actionTimeOut }) => {
  const scheme = getScheme({ theme, type });

  useEffect(() => {
    let timerId;
    if (actionTimeOut && action) {
      timerId = setTimeout(action, actionTimeOut);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [actionTimeOut, action]);

  return (
    <Wrapper scheme={scheme}>
      <Container scheme={scheme} data-testid="test-toast-container">
        <TextWrapper>
          <Text scheme={scheme}>{children}</Text>
        </TextWrapper>
        <ButtonWrapper>
          {action && (
            <CloseButton data-testid="test-toast-action" stroke={theme.palette.primary.darkLight} onClick={action} />
          )}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default withTheme(Toast);
