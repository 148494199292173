import { Theme } from '@mui/material/styles';

import { Icons } from 'config/icons/feather';

import { AlertTypes, Schema } from './types';

const getSchema = ({ theme, type }: { theme: Theme; type: AlertTypes }): Schema => {
  const { palette } = theme;

  switch (type) {
    case AlertTypes.Warn:
      return {
        color: palette.warning.main,
        bgColor: palette.warning.light,
        icon: Icons.AlertCircle,
        iconStroke: palette.warning.main,
      };
    case AlertTypes.Error:
      return {
        color: palette.error.main,
        bgColor: palette.error.light,
        icon: Icons.AlertTriangle,
        iconStroke: palette.error.main,
      };
    case AlertTypes.Success:
      return {
        color: palette.success.dark,
        bgColor: palette.success.light,
        icon: Icons.CheckCircle,
        iconStroke: palette.success.dark,
      };
    case AlertTypes.Info:
    case AlertTypes.Primary:
    default:
      return {
        color: palette.text.primary,
        bgColor: palette.primary.light,
        icon: Icons.Info,
        iconStroke: palette.primary.main,
      };
  }
};

export default getSchema;
