import { cloneElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import ThemeProvider, { ThemeTypes } from 'contexts/ThemeContext';
import { NotifierProvider } from 'contexts/NotifierContext';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { isFalse } from 'lib/utils/boolean';
import { BRANDED_PAGE } from 'config/constants/queryParams';

import Notifier from 'components/ui/atoms/Notifier';
import NoSpacingTemplate from 'components/templates/NoSpacingTemplate';
import Stepper from 'components/ui/atoms/Stepper';

import useRedirect from './hooks/useRedirect';
import { getSteps, stepContents } from './config';
import Title from './components/Title';
import Header from './components/Header';
import { StepContentContainer, StepContentWrapper, StepperContainer } from './styled';

const ApplyStepsForm = () => {
  const isMobile = useCheckMobileScreen();
  const { step: currentStep, activeStepNumber } = useRedirect();

  const [searchParams] = useSearchParams();
  const brandedPage = !isFalse(searchParams.get(BRANDED_PAGE));

  const steps = getSteps();

  return (
    <ThemeProvider themeType={ThemeTypes.Green}>
      <NotifierProvider>
        <NoSpacingTemplate>
          <Header brandedPage={brandedPage} />
          <StepperContainer>
            <Stepper steps={steps} activeStep={activeStepNumber} />
          </StepperContainer>
          <StepContentContainer>
            <Title step={currentStep} brandedPage={brandedPage} />
            {stepContents.map(
              ({ step, content }) =>
                step === currentStep && (
                  <StepContentWrapper key={step}>
                    {cloneElement(content, { step, isMobile, brandedPage })}
                  </StepContentWrapper>
                ),
            )}
          </StepContentContainer>
          <Notifier />
        </NoSpacingTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default ApplyStepsForm;
