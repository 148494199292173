import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import {
  ShortApplicationFormQueryVariables,
  ShortApplicationFormQuery as ShortApplicationFormQueryOriginalData,
} from './shortApplicationForm.generated';

const shortApplicationFormQuery = loader('./shortApplicationForm.graphql');

type UseShortApplicationFormQueryArgs = {
  variables: ShortApplicationFormQueryVariables;
  skip: boolean;
};

export type ShortApplicationFormData = ShortApplicationFormQueryOriginalData['shortApplicationForm'];

const getData = (data: ShortApplicationFormQueryOriginalData): ShortApplicationFormData => data?.shortApplicationForm;

const useShortApplicationFormQuery = ({ variables, skip = false }: UseShortApplicationFormQueryArgs) => {
  const { data, ...rest } = useQuery(shortApplicationFormQuery, {
    fetchPolicy: 'cache-first',
    variables,
    skip,
  });

  return {
    data: getData(data),
    ...rest,
  };
};

export default useShortApplicationFormQuery;
