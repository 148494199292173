import { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from 'components/ui/atoms/buttons/Button';
import UserAgreementText, { UserAgreementTextProps } from 'components/shared/molecules/UserAgreementText';
import type { FormFooterProps } from 'components/ui/molecules/FormWithValidation/types';
import { ButtonBlockProps, Alignment } from './types';

const ButtonBlock = styled.div(
  ({ blockAlignment }: ButtonBlockProps) => css`
    display: flex;
    flex-direction: column;
    align-items: ${blockAlignment};
  `,
);

const FormFooter = ({
  disabled,
  submitHandler,
  buttonConfirmationText,
  buttonText,
  footerAlignment,
}: FormFooterProps) => {
  const [isAgreeWithPolicy, setIsAgreeWithPolicy] = useState(true);

  const handleAgree: UserAgreementTextProps['handleAgree'] = ({ currentTarget }) =>
    setIsAgreeWithPolicy(currentTarget.checked);

  return (
    <ButtonBlock blockAlignment={footerAlignment || Alignment.End}>
      <UserAgreementText
        isAgreeWithPolicy={isAgreeWithPolicy}
        handleAgree={handleAgree}
        label={buttonConfirmationText}
      />
      <Button
        variant="contained"
        color="primary"
        disableRipple
        disableElevation
        disabled={disabled || !isAgreeWithPolicy}
        onClick={submitHandler}
      >
        {buttonText || 'Submit Application'}
      </Button>
    </ButtonBlock>
  );
};

export default FormFooter;
