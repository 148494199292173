import {
  YUP_STRING,
  YUP_MIXED,
  YUP_NULLABLE_REQUIRED,
  YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  YUP_REQUIRED_INTEGER,
  YUP_REQUIRED_MONEY,
  YUP_MULTI_SELECT_REQUIRED,
  YUP_REQUIRED_PHONE_NUMBER,
  YUP_DATE_REQUIRED_PAST,
} from 'config/constants/yup';
import { getValidationRuleWhen } from 'lib/yup/methods/getValidationRuleWhen';

export const DEFAULT_VALUES = {
  businessName: '',
  businessEmployeesCount: null,
  merchantFirstName: '',
  merchantLastName: '',
  merchantEmail: null,
  merchantCellPhone: null,
  receivedPpp2: '',
  submittedForgivenessAppPppLoan: '',
  shutDown2020: '',
  quartersBusinessShutDown: [],
  quarterWithDates: '',
  businessStartedAt: null,
  grossReceipts2019Q1Cents: null,
  grossReceipts2019Q2Cents: null,
  grossReceipts2019Q3Cents: null,
  grossReceipts2019Q4Cents: null,
  grossReceipts2020Q1Cents: null,
  grossReceipts2020Q2Cents: null,
  grossReceipts2020Q3Cents: null,
  grossReceipts2020Q4Cents: null,
  grossReceipts2021Q1Cents: null,
  grossReceipts2021Q2Cents: null,
  grossReceipts2021Q3Cents: null,
  grossReceipts2021Q4Cents: null,
};

export const requiredValidationFields = {
  businessName: YUP_NULLABLE_REQUIRED,
  merchantEmail: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  businessEmployeesCount: YUP_REQUIRED_INTEGER,
  merchantFirstName: YUP_NULLABLE_REQUIRED,
  merchantLastName: YUP_NULLABLE_REQUIRED,
  merchantCellPhone: YUP_REQUIRED_PHONE_NUMBER,
  receivedPpp2: YUP_NULLABLE_REQUIRED,
  submittedForgivenessAppPppLoan: getValidationRuleWhen(YUP_STRING, ['receivedPpp2'], {
    is: (value: string) => value === 'true',
    then: YUP_NULLABLE_REQUIRED,
  }),
  shutDown2020: YUP_NULLABLE_REQUIRED,
  quartersBusinessShutDown: getValidationRuleWhen(YUP_MIXED, ['shutDown2020'], {
    is: (value: string) => value === 'true',
    then: YUP_MULTI_SELECT_REQUIRED,
  }),
  businessStartedAt: YUP_DATE_REQUIRED_PAST,
  grossReceipts2019Q1Cents: YUP_REQUIRED_MONEY,
  grossReceipts2019Q2Cents: YUP_REQUIRED_MONEY,
  grossReceipts2019Q3Cents: YUP_REQUIRED_MONEY,
  grossReceipts2019Q4Cents: YUP_REQUIRED_MONEY,
  grossReceipts2020Q1Cents: YUP_REQUIRED_MONEY,
  grossReceipts2020Q2Cents: YUP_REQUIRED_MONEY,
  grossReceipts2020Q3Cents: YUP_REQUIRED_MONEY,
  grossReceipts2020Q4Cents: YUP_REQUIRED_MONEY,
  grossReceipts2021Q1Cents: YUP_REQUIRED_MONEY,
  grossReceipts2021Q2Cents: YUP_REQUIRED_MONEY,
  grossReceipts2021Q3Cents: YUP_REQUIRED_MONEY,
  grossReceipts2021Q4Cents: YUP_REQUIRED_MONEY,
};

export const saveForLaterValidationFields = {
  businessName: YUP_NULLABLE_REQUIRED,
  merchantEmail: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  businessEmployeesCount: YUP_REQUIRED_INTEGER,
  merchantFirstName: YUP_NULLABLE_REQUIRED,
  merchantLastName: YUP_NULLABLE_REQUIRED,
  merchantCellPhone: YUP_REQUIRED_PHONE_NUMBER,
};
