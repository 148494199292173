import BaseSelect from 'components/ui/atoms/forms/Select';
import Input from 'components/ui/atoms/forms/Input';
import EnumSelect from 'components/ui/atoms/forms/EnumSelect';

export const PppQuestionsFields = [
  {
    id: 'receivedPpp2',
    name: 'receivedPpp2',
    label: 'Did you receive a second round PPP loan?',
    placeholder: 'Yes/No',
    control: <BaseSelect />,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
    required: true,
    isDefaultVisible: true,
    dependentFieldNames: ['submittedForgivenessAppPppLoan'],
  },
  {
    id: 'submittedForgivenessAppPppLoan',
    name: 'submittedForgivenessAppPppLoan',
    label: 'Have you since submitted a forgiveness application for your PPP loan?',
    placeholder: 'Yes/No',
    control: <BaseSelect />,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
    required: true,
    isDefaultVisible: false,
    helperText:
      'Understand that you may not receive an employee retention credit for any wages reported on a PPP forgiveness application. This is why it is important to optimize which wages are included on your PPP forgiveness application, and which are omitted from the application, so the credit may be applied to wages selectively.',
  },
  {
    id: 'shutDown2020',
    name: 'shutDown2020',
    label: 'At any point during 2020 was your business shut down by government order?',
    placeholder: 'Yes/No',
    control: <BaseSelect />,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
    required: true,
    isDefaultVisible: true,
    dependentFieldNames: ['quartersBusinessShutDown', 'quarterWithDates'],
    helperText: `For this to apply, you must meet 3 tests:
      1. The orders have to come from the federal government, or a state or local government that has jurisdiction over the business.\r\n
      2. The orders have to limit commerce, travel, or group meetings due to COVID-19; and
      3. The orders must affect an employer’s operation of its trade or business.
      See this article for a further discussion of shutdown eligibility: https://go.rlz.io/f7sv1`,
  },
  {
    id: 'quartersBusinessShutDown',
    name: 'quartersBusinessShutDown',
    label: 'Select all the quarters during which your business was shut down due to government order. ',
    placeholder: 'Yes/No',
    control: <EnumSelect enumName="ErcQuarters" multiple />,
    required: true,
    isDefaultVisible: false,
  },
  {
    id: 'quarterWithDates',
    name: 'quarterWithDates',
    label:
      'If your business was only partially shut down during a certain quarter please indicate the quarter below along with the dates.',
    placeholder: '',
    control: <Input />,
    required: false,
    isDefaultVisible: false,
  },
];
