import DefaultTheme from '../../styles/themes/defaultTheme';
import GreenTheme from '../../styles/themes/greenTheme';

export enum ThemeTypes {
  Default = 'default',
  Green = 'green',
}

export const THEMES = {
  [ThemeTypes.Default]: DefaultTheme,
  [ThemeTypes.Green]: GreenTheme,
};
