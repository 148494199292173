import { useMemo, forwardRef, ChangeEvent, ReactNode } from 'react';

import type { BaseSelectProps, Value } from './types';
import type { Option } from '../models';

import { getCustomEvent } from './helpers';
import { getModel as getModelDefault } from '../models';
import { StyledSelect } from './styled';
import getOptionsDefault from './components/SelectOptions';
import SelectIcon from './components/SelectIcon';

const BaseSelect = <TV extends string, TL extends string, T extends Option<TV, TL>>(
  {
    value,
    options,
    disabled,
    onChange,
    getModel,
    renderValue,
    getOptions = getOptionsDefault,
    ...props
  }: BaseSelectProps<TV, TL, T>,
  ref: any,
) => {
  const model = useMemo(() => {
    const customModel = getModel?.() ?? {};

    return getModelDefault({ options, ...customModel });
  }, [getModel, options]);

  const handleChange = (event: ChangeEvent<{ name: string; value: Value }>, child: ReactNode): void => {
    onChange?.(getCustomEvent(event), child);
  };

  const handleRenderValue = renderValue
    ? (selectedValue: Value) => renderValue?.({ selectedValue, model, disabled })
    : undefined;

  return (
    <StyledSelect
      value={value}
      ref={ref}
      IconComponent={SelectIcon}
      defaultValue=""
      // @ts-expect-error use new SelectChangeEvent and fix getCustomEvent
      onChange={handleChange}
      // @ts-expect-error fix Value issue
      renderValue={handleRenderValue}
      disabled={disabled}
      notched={false}
      {...props}
    >
      {getOptions(model)}
    </StyledSelect>
  );
};

export default forwardRef(BaseSelect);
