import { useParams, useSearchParams } from 'react-router-dom';

import { ORIGIN, MODE } from 'config/constants/queryParams';

type GetReferralIdFromOrigin = (origin: string) => string;

const useReferralId = (getReferralIdFromOrigin: GetReferralIdFromOrigin): string | null => {
  const params = useParams();
  const { referralId } = params || {};
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(MODE);
  const origin = searchParams.get(ORIGIN);

  const referralIdFromOrigin = mode === 'iframe' && origin && getReferralIdFromOrigin(origin);

  return referralId || referralIdFromOrigin || null;
};

export default useReferralId;
