import { styled } from '@mui/material/styles';
import { withTheme } from '@emotion/react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { formatToISOStringDateOnly } from 'lib/utils/date';

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg';

const StyledDatePicker = styled(DatePicker)(({ theme: { typography, breakpoints, colors, palette }, error }) => ({
  '& .MuiOutlinedInput-root': {
    ...typography.input,
    [breakpoints.down('sm')]: {
      ...typography.inputForMobile,
    },
    height: '40px',
    background: colors.white,
    borderColor: palette.border,

    '& .MuiOutlinedInput-input': {
      boxSizing: 'border-box',

      '&::placeholder': {
        opacity: 1,
        color: palette.text.secondary,
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: error ? palette.error.main : palette.border,
    },

    '&:hover:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.border,
    },

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },

      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.primary.main,
      },
    },

    '&.Mui-disabled': {
      background: palette.form.disabled.background,
      color: palette.form.disabled.text,
      '&::placeholder': {
        color: palette.form.disabled.text,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.form.disabled.border,
      },
    },

    '& .MuiIconButton-root': {
      borderRadius: 0,
      backgroundColor: 'transparent',

      '& > svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
}));

const KeyboardDatePicker = ({ onChange, onlyDate = true, value, placeholder, error, ...props }) => {
  const handleChange = (date) => onChange(onlyDate ? formatToISOStringDateOnly(date) : date);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        onChange={handleChange}
        value={value ? dayjs(value) : null}
        slots={{
          openPickerIcon: IconCalendar,
        }}
        slotProps={{
          openPickerButton: {
            color: 'primary',
          },
          textField: { placeholder, error },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default withTheme(KeyboardDatePicker);
