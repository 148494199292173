const getScheme = ({ theme: { palette }, type }) => {
  switch (type) {
    case 'success':
      return {
        color: palette.secondary.contrastText,
        bgColor: palette.success.dark,
        icon: 'checkCircle',
        iconStroke: palette.secondary.contrastText,
      };
    case 'error':
    default:
      return {
        color: palette.secondary.contrastText,
        bgColor: palette.error.main,
        icon: 'alertTriangle',
      };
  }
};

export default getScheme;
