import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BaseIconButtonProps } from './types';

const StyledIconButton = styled.button<Pick<BaseIconButtonProps, 'stroke'>>(
  ({ theme: { palette }, stroke }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 14px;
    background-color: transparent;
    border: none;
    vertical-align: middle;

    &:disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }

    svg {
      stroke: ${stroke ?? palette.primary.dark};
    }

    &:hover {
      background-color: unset;

      svg {
        stroke: ${stroke ?? palette.primary.main};
        opacity: ${stroke ? 0.5 : 1};
      }
    }
  `,
);

export default StyledIconButton;
