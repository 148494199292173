import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from 'config/constants/dateTimeFormats';
import { formattedDate, formatToISOStringDateOnly, isDateValid } from 'lib/utils/date';
import noop from 'lib/utils/noop';

import KeyboardDatePicker from 'components/ui/atoms/forms/KeyboardDatePicker';

const isValid = (value) => {
  const date = dayjs(value);
  return date.isValid();
};

const getAge = (isoString) => {
  if (!isValid(isoString)) return '';

  return `, ${dayjs(Date.now()).diff(dayjs(isoString), 'year')}`;
};

const getDisplayValue = (value, withAge) => {
  if (!isValid(value)) return '';

  return `${formattedDate(value)}${withAge ? getAge(value) : ''}`;
};

const DateInput = (props) => {
  const {
    id,
    required,
    name,
    onChange = noop,
    onBlur = noop,
    onFocus = noop,
    value,
    withAge = false,
    onlyDate = true,
    inputFormat,
    placeholder,
    ...other
  } = props;

  const [focusing, setFocusing] = useState(false);
  const [displayValue, setDisplayValue] = useState(getDisplayValue(value, withAge));

  const handleChange = (date) => {
    onChange({ target: { name, value: date } });
    if (isDateValid(date)) {
      setDisplayValue(getDisplayValue(date, withAge));
    }
  };
  const handleAccept = async (date) => {
    setFocusing(false);
    setDisplayValue(getDisplayValue(date, withAge));
    await onChange({
      target: { name, value: onlyDate ? formatToISOStringDateOnly(date) : date },
    });
    onBlur({
      target: { name, value: onlyDate ? formatToISOStringDateOnly(date) : date },
    });
  };
  const handleFocus = (event) => {
    setFocusing(true);
    onFocus(event);
  };
  const handleBlur = (event) => {
    setFocusing(false);
    setDisplayValue(getDisplayValue(event.target.value, withAge));
    onBlur({ target: { name, value } });
  };

  useEffect(() => {
    setDisplayValue(getDisplayValue(value, withAge));
  }, [value]);

  const inputValue = focusing || !withAge ? undefined : displayValue;

  return (
    <KeyboardDatePicker
      id={id}
      name={name}
      value={value}
      placeholder={placeholder ?? DEFAULT_DATE_FORMAT}
      inputFormat={inputFormat ?? DEFAULT_DATE_FORMAT}
      inputValue={inputValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onAccept={handleAccept}
      required={required}
      onlyDate={onlyDate}
      invalidDateMessage=""
      maxDateMessage=""
      minDateMessage=""
      {...other}
    />
  );
};

export default DateInput;
