import { ReactNode } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

type Props = {
  children?: ReactNode;
};

const NoSpacingTemplate = ({ children }: Props) => <Wrapper>{children}</Wrapper>;

export default NoSpacingTemplate;
