import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { CustomTestType } from 'types/testType';
import { FontWeight, Margin, TextCase, TextColor } from 'types/theme/text';
import { H2 } from 'styles/typographyVariants';

import { TextType } from '../types';

type TitleLargeProps = CustomTestType &
  TextType & {
    maxWidth?: number;
  };

export const StyledTitle = styled.h2<
  Pick<TitleLargeProps, 'fontWeight' | 'textCase' | 'textColor' | 'maxWidth' | 'marginBottom'>
>(
  ({ theme: { palette, spacing }, textCase, textColor, fontWeight, maxWidth, marginBottom }) => css`
    ${H2};
    height: auto;
    margin: 0 0 ${spacing(marginBottom ?? Margin.Zero)};
    color: ${textColor ? palette.text[textColor] : palette.text[TextColor.Primary]};
    font-weight: ${fontWeight ?? FontWeight.Normal};
    text-transform: ${textCase ?? TextCase.None};

    ${maxWidth &&
    css`
      max-width: ${maxWidth}px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  `,
);

const TitleLarge = ({ children, dataTestId, ...otherProps }: TitleLargeProps) => (
  <StyledTitle data-testid={dataTestId} {...otherProps}>
    {children}
  </StyledTitle>
);

export default TitleLarge;
