import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export type IsoString = string;
type Format = string;

export const formatTime = (isoString: IsoString, format: Format) => dayjs(isoString).format(format);

export const formattedDate = (isoString: IsoString) => (isoString ? formatTime(isoString, 'MM/DD/YYYY') : null);

export const isDateValid = (date: string) => date !== 'Invalid Date';

export const formatToISOStringDateOnly = (isoString: IsoString) =>
  isoString ? dayjs(isoString).utc(true).format('YYYY-MM-DD') : isoString;
