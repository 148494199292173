import { forwardRef } from 'react';

import Select from 'components/ui/atoms/forms/Select/BaseSelect';

import type { MultipleSelectProps } from './types';
import type { Option, OptionValue } from '../models';

import { getEvent, convertValueToValidType } from './helpers';
import { renderValue } from './components/SelectValue';

const MultipleSelect = <TV extends string, TL extends string, T extends Option<TV, TL>>(
  { value, name, options, onChange, disabled, ...props }: MultipleSelectProps<TV, TL, T>,
  ref: any,
) => {
  const onDeleteItem = (deletedValue: OptionValue) => {
    const validValue = convertValueToValidType(value);

    const newValue = validValue.filter((val) => val !== deletedValue);
    onChange?.(getEvent(newValue, name));
  };

  // TODO: add correct type
  const handleChange = (event: any) => {
    onChange?.(getEvent(event.target.value, event.target.name));
  };

  // TODO: add correct type
  const handleRenderValue = (params: any) => renderValue({ ...params, handleDelete: onDeleteItem });

  return (
    <Select
      ref={ref}
      name={name}
      value={convertValueToValidType(value)}
      options={options}
      onChange={handleChange}
      disabled={disabled}
      renderValue={handleRenderValue}
      {...props}
    />
  );
};

export default forwardRef(MultipleSelect);
