import styled from '@emotion/styled';

import PageTitle from 'components/ui/atoms/text/PageTitle';

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const Header = () => (
  <Container>
    <PageTitle>One Short Step to Funding</PageTitle>
    <p>
      Thank you for placing your trust in us. Please take a moment to complete the form below. We will process your
      request right away.
    </p>
  </Container>
);

export default Header;
