import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  HOME,
  ERC as ERCRoute,
  APPLY,
  APPLY_FORM,
  ONE_STEP_FORM,
  CCP_FORM,
  CALCULATOR,
  RESUBSCRIBE,
} from 'config/routes';

import Home from 'components/pages/Home';
import ERC from 'components/pages/ERC';
import ApplyLanding from 'components/pages/ApplyLanding';
import ApplyStepsForm from './components/pages/ApplyStepsForm';
import OneStep from './components/pages/OneStep';
import Ccp from './components/pages/CCP';
import Calculator from './components/pages/Calculator';
import Resubscribe from './components/pages/Resubscribe';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={ERCRoute} element={<ERC />}>
          <Route path=":referralId" element={<ERC />} />
        </Route>
        <Route path={APPLY_FORM} element={<ApplyStepsForm />}>
          <Route path=":referralId" element={<ApplyStepsForm />} />
        </Route>
        <Route path={APPLY} element={<ApplyLanding />}>
          <Route path=":referralId" element={<ApplyLanding />} />
        </Route>
        <Route path={ONE_STEP_FORM} element={<OneStep />}>
          <Route path=":referralId" element={<OneStep />} />
        </Route>
        <Route path={CCP_FORM} element={<Ccp />}>
          <Route path=":referralId" element={<Ccp />} />
        </Route>
        <Route path={RESUBSCRIBE} element={<Resubscribe />} />
        <Route path={CALCULATOR} element={<Calculator />} />
      </Routes>
    </Router>
  );
};

export default App;
