import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { LinkProps } from './type';

const StyledLink = styled.a(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.main};

    &:hover {
      color: ${palette.primary.dark};
    }
  `,
);

const Link = ({ children, ...props }: LinkProps) => (
  <StyledLink className="link" {...props}>
    {children}
  </StyledLink>
);

export default Link;
