import Checkbox from 'components/ui/atoms/forms/Checkbox';

import type { UserAgreementTextProps } from './types';
import { DescriptionWrapper } from './styled';

const DEFAULT_BRANDED_CONFIRMATION_TEXT =
  'By submitting this form, I allow Premium Merchant Funding (PMF) and its associates to contact me by phone, text, or email.';

const DEFAULT_CONFIRMATION_TEXT =
  'By submitting this form, I consent to receive communication at the contact information provided above.';

const UserAgreementText = ({ isAgreeWithPolicy, handleAgree, label, isBranded = true }: UserAgreementTextProps) => {
  return (
    <DescriptionWrapper>
      <Checkbox checked={isAgreeWithPolicy} onChange={handleAgree} name="user-agreement" />
      <span>{label || (isBranded ? DEFAULT_BRANDED_CONFIRMATION_TEXT : DEFAULT_CONFIRMATION_TEXT)}</span>
    </DescriptionWrapper>
  );
};

export default UserAgreementText;
