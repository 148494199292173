import FormatInput from 'components/ui/atoms/forms/FormatInput';

import { TAX_ID_FORMAT, TAX_ID_PLACEHOLDER } from './config';
import type TaxIdInputProps from './types';

const TaxIdInput = (props: TaxIdInputProps): JSX.Element => (
  <FormatInput format={TAX_ID_FORMAT} placeholder={TAX_ID_PLACEHOLDER} {...props} />
);

export default TaxIdInput;
