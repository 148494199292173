import { styled } from '@mui/material/styles';

import FormHelperText from '@mui/material/FormHelperText';

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  ...theme.typography.t3,
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
  margin: 0,
  marginTop: theme.spacing(0.375),

  '&.Mui-disabled': {
    color: theme.palette.text.secondary,
  },
}));

export default StyledHelperText;
