import { MaskStringInput, MaskStringInputProps, EventHandler } from 'components/ui/atoms/forms/MaskInput';

const StringInput = ({ onChange, onBlur, ...props }: Omit<MaskStringInputProps, 'maskedOptions' | 'nullable'>) => {
  const handleBlur: EventHandler = (event) => {
    const { target } = event;
    const newEvent = {
      ...event,
      target: {
        ...target,
        value: typeof target.value === 'string' ? target.value.trim() : target.value,
      },
    };

    onChange?.(newEvent);
    onBlur?.(newEvent);
  };

  return <MaskStringInput maskedOptions={{ mask: /^\S.*$/ }} onChange={onChange} onBlur={handleBlur} {...props} />;
};

export default StringInput;
