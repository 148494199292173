import { LABEL_FIELD, VALUE_FIELD } from './config';
import type { Model, Option } from './types';
import {
  getOptionLabel as getOptionLabelDefault,
  getOptionValue as getOptionValueDefault,
  findOption as findOptionDefault,
} from './helpers';

const getModel = <TV extends string, TL extends string, T extends Option<TV, TL>>({
  options,
  labelFieldName,
  valueFieldName,
  getOptionLabel,
  getOptionValue,
  findOption,
}: Partial<Model<TV, TL, T>>): Model<TV, TL, T> => {
  const newGetOptionValue =
    getOptionValue ?? ((option) => getOptionValueDefault(option, valueFieldName || VALUE_FIELD));

  return {
    options,
    labelFieldName,
    valueFieldName,
    getOptionLabel: getOptionLabel ?? ((option) => getOptionLabelDefault(option, labelFieldName || LABEL_FIELD)),
    getOptionValue: newGetOptionValue,
    findOption: findOption ?? ((optionsList, value) => findOptionDefault(optionsList, value, newGetOptionValue)),
  };
};

export default getModel;
