import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const UpdateIntakeFormBasicInfoV2Mutation = loader(
  '../../../graphql/mutations/intakeForm/updateIntakeFormBasicInfoV2.graphql',
);

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useUpdateIntakeFormBasicInfoV2Mutation = ({ onCompleted, onError }: Props) => {
  const [updateIntakeFormBasicInfo, mutationResult] = useMutation(UpdateIntakeFormBasicInfoV2Mutation, {
    onCompleted,
    onError,
  });

  return [updateIntakeFormBasicInfo, mutationResult] as MutationTuple<any, any>;
};

export default useUpdateIntakeFormBasicInfoV2Mutation;
