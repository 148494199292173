import { useCallback, useEffect, useMemo, useState } from 'react';

import usePublicFinancingRequestQuery from 'api/query/usePublicFinancingRequestQuery';
import useTypeQueryHook from 'api/hooks/useTypeQueryHook';
import useUpdatePublicFinancingRequestMutation from 'api/mutations/calculator/useUpdatePublicFinancingRequestMutation';
import { Enums } from 'api/types/common/enum';

import { getTermUnitText } from './utils';

const FINANCING_REQUEST_TERM_UNIT_ENUM = 'FinancingRequestTermUnit';

const usePublicFinancingRequest = (uuid: string) => {
  const [data, setData] = useState(null);

  const { data: queryData } = usePublicFinancingRequestQuery({
    variables: {
      uuid,
    },
    skip: !uuid,
  });

  const { termUnit, uuid: queryUuid } = queryData ?? {};

  const { data: enumData } = useTypeQueryHook({ name: FINANCING_REQUEST_TERM_UNIT_ENUM });
  // TODO: check types in getTermUnitText & getEnumDescription, case when enumData undefined
  const termUnitText = useMemo(() => getTermUnitText(enumData as Enums, termUnit), [enumData, termUnit]);

  const [updatePublicFinancingRequest, { data: mutationData, loading: updateFormLoading }] =
    useUpdatePublicFinancingRequestMutation();

  const handleUpdate = useCallback(
    async (newValues: any) => {
      if (queryUuid) {
        await updatePublicFinancingRequest({
          variables: { ...newValues, uuid: queryUuid },
        });
      }
    },
    [queryUuid],
  );

  useEffect(() => {
    if (queryData) {
      setData(queryData);
    }
  }, [queryData]);

  useEffect(() => {
    if (mutationData) {
      setData(mutationData.updatePublicFinancingRequest);
    }
  }, [mutationData]);

  return {
    data,
    termUnitText,
    loading: updateFormLoading,
    handleUpdate,
  };
};

export default usePublicFinancingRequest;
