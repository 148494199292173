import {
  YUP_NULLABLE_REQUIRED,
  YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  YUP_REQUIRED_PHONE_NUMBER,
} from 'config/constants/yup';

import PhoneInput from 'components/ui/atoms/forms/PhoneInput';
import EmailInput from 'components/ui/atoms/forms/EmailInput';
import StringInput from 'components/ui/atoms/forms/StringInput';

enum IntakeFormFields {
  FirstName = 'merchantFirstName',
  LastName = 'merchantLastName',
  Email = 'merchantEmail',
  CellPhone = 'merchantCellPhone',
}

export const fields = [
  {
    id: 'first-name',
    name: IntakeFormFields.FirstName,
    label: 'First Name',
    placeholder: 'Enter first name',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'add-new-lastName',
    name: IntakeFormFields.LastName,
    control: <StringInput />,
    label: 'Last Name',
    required: true,
    placeholder: 'Enter last name',
  },
  {
    id: 'add-new-email',
    name: IntakeFormFields.Email,
    control: <EmailInput />,
    label: 'Email Address',
    required: true,
    placeholder: 'Enter email address',
  },
  {
    id: 'add-new-cellPhone',
    name: IntakeFormFields.CellPhone,
    control: <PhoneInput />,
    label: 'Phone Number',
    required: true,
    placeholder: 'xxx-xxx-xxxx',
  },
];

export const DEFAULT_VALUES = {
  [IntakeFormFields.FirstName]: '',
  [IntakeFormFields.LastName]: '',
  [IntakeFormFields.Email]: null,
  [IntakeFormFields.CellPhone]: null,
};

export const requiredValidationFields = {
  [IntakeFormFields.Email]: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  [IntakeFormFields.FirstName]: YUP_NULLABLE_REQUIRED,
  [IntakeFormFields.LastName]: YUP_NULLABLE_REQUIRED,
  [IntakeFormFields.CellPhone]: YUP_REQUIRED_PHONE_NUMBER,
};
