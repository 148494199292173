import { cloneElement, useMemo } from 'react';

import Typography from 'components/ui/atoms/Typography';

import Label from './Label';
import type { TextFieldProps } from './types';

const TextField = ({ textField, label, value, ...restProps }: TextFieldProps) => {
  const noValue = value === null || value === undefined || !value.length || !Object.entries(value).length;

  const text = useMemo(() => {
    if (noValue) return '–';

    if (Array.isArray(value)) return value.join(', ');
    return restProps['data-name'] ?? value;
  }, [value]);

  return (
    <div>
      {label && <Label>{label}</Label>}
      {cloneElement(textField ?? <Typography variant="t2" />, { children: text, ...restProps })}
    </div>
  );
};

export default TextField;
