import styled from '@emotion/styled';
import { css } from '@emotion/react';

import backgroundForMobile from 'assets/background-mobile.png';
import background from 'assets/background.png';

export const Main = styled.div(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: 100%;
    min-height: 300px;
    padding: ${spacing(12.5, 6)};
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.41)), url(${background});
    background-size: cover;
    background-position: inherit;

    ${down(breakpoints.md)} {
      padding: ${spacing(12.5, 0, 0)};
      background-image: linear-gradient(rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.41)), url(${backgroundForMobile});
      background-position: center;
    }
  `,
);

export const FormContainer = styled.div(
  ({
    theme: {
      breakpoints: { values: breakpoints, up },
    },
  }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${up(breakpoints.xl)} {
      max-width: 2000px;
    } ;
  `,
);
