import { ForwardedRef, forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import icons from 'config/icons/feather';
import type { DefaultIconProps, IconProps } from './types';

const UnsupportedIcon = forwardRef((props: DefaultIconProps, ref: ForwardedRef<HTMLElement>) => {
  return (
    <i ref={ref} {...props}>
      Unsupported Icon
    </i>
  );
});

const Icon = forwardRef(({ type, size, theme: { iconSize }, ...props }: IconProps, ref: ForwardedRef<HTMLElement>) => {
  const customSize = size ?? iconSize;

  const Component = useMemo(() => {
    if (!(type in icons)) return UnsupportedIcon;

    return icons[type];
  }, [type]);

  return <Component ref={ref} size={customSize} {...props} />;
});

const StyledIcon = styled(withTheme(Icon))`
  vertical-align: middle;
  transition: 0.2s;
`;

export default StyledIcon;
