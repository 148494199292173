import { PaletteMode } from '@mui/material';

import createTheme from './muiThemeOverrides';
import colors from '../colors';

const DefaultTheme = {
  background: {
    main: colors.whiteDark,
    deep: colors.whiteGrey,
    light: colors.white,
    secondary: `linear-gradient(90deg, ${colors.sapphire} 10.86%, ${colors.sapphireLight} 89.92%)`,
  },
  palette: {
    mode: 'light' as PaletteMode,
    primary: {
      main: colors.blue,
      light: colors.blueLighter,
      lighter: colors.blueExtraLighter,
      dark: colors.blueDarker,
      darkLight: colors.blackLight,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.sapphire,
      light: colors.sapphireLight,
      dark: colors.sapphireDark,
      contrastText: colors.white,
    },
    error: {
      main: colors.red,
      light: colors.redLight,
      dark: colors.redBright,
    },
    warning: {
      main: colors.orange,
      light: colors.orangeLight,
    },
    success: {
      main: colors.green,
      light: colors.greenLight,
      dark: colors.greenDown,
    },
    text: {
      primary: colors.blackLight,
      secondary: colors.grayBlue,
      disabled: colors.grayBlue,
      contrastText: colors.white,
    },
    buttons: {
      confirm: colors.blue,
      remove: colors.redBright,
      cancel: colors.whiteGrey,
      text: {
        primary: {
          text: colors.blue,
        },
        secondary: {
          text: colors.grayBlue,
        },
        disabled: {
          text: colors.grayBlue,
        },
      },
      outlined: {
        primary: {
          text: colors.blue,
        },
        secondary: {
          text: colors.blackLight,
          border: colors.grayLight,
          hoverBackground: colors.grayLighter,
        },
        disabled: {
          text: colors.grayBlue,
          background: colors.whiteDark,
          border: 'transparent',
        },
      },
      contained: {
        default: {
          background: colors.white,
          hoverBackground: colors.grayLighter,
        },
        primary: {
          text: colors.white,
          background: colors.blue,
          hoverBackground: colors.blueDarker,
        },
        secondary: {
          text: colors.white,
          background: colors.red,
          hoverBackground: colors.redBright,
        },
        inherit: {
          text: colors.white,
          background: colors.green,
          hoverBackground: colors.greenDown,
        },
        disabled: {
          text: colors.grayBlue,
          background: colors.blueLight,
          border: 'transparent',
        },
      },
    },
    form: {
      main: colors.grayLight,
      active: colors.blue,
      disabled: {
        text: colors.grayBlue,
        background: colors.blueLight,
        border: colors.grayBlue,
      },
    },
    scrollThumb: colors.blueLight,
    paginationHover: colors.blueLight,
    border: colors.grayLight,
  },
};

export default createTheme(DefaultTheme);
