import { toString } from 'lodash';

import CustomNumberFormat from 'components/ui/atoms/CustomNumberFormat';
import Input from 'components/ui/atoms/forms/Input';

import type { ChangeEventHandler, FormatInputProps, BlurEventHandler } from './types';

const FormatInput = ({ value, onChange, onBlur, format, ...props }: FormatInputProps) => {
  const handleChange: ChangeEventHandler = (formatValues, { event }) => {
    if (!event) return;

    onChange?.({
      preventDefault: () => event.preventDefault(),
      target: {
        name: event.target.name,
        value: formatValues.value,
      },
    });
  };

  const handleBlur: BlurEventHandler = (event) =>
    onBlur?.({
      preventDefault: () => event.preventDefault(),
      target: {
        name: event.target.name,
        value: toString(value),
      },
    });

  return (
    <Input
      value={value}
      inputComponent={CustomNumberFormat}
      onBlur={handleBlur}
      inputProps={{
        onValueChange: handleChange,
        format,
      }}
      {...props}
    />
  );
};

export default FormatInput;
