enum TextColor {
  Primary = 'primary',
  PrimaryBlue = 'primaryBlue',
  Secondary = 'secondary',
  Action = 'action',
  Error = 'error',
  ContrastText = 'contrastText',
}

export default TextColor;
