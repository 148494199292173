import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Wrapper = styled.div(
  ({ theme: { spacing } }) => css`
    margin-top: ${spacing(7)};
  `,
);

const NotFound = () => {
  return (
    <Wrapper>
      <h2>Page not found</h2>
    </Wrapper>
  );
};

export default NotFound;
