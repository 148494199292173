import FormatInput from 'components/ui/atoms/forms/FormatInput';

import { PHONE_NUMBER_FORMAT, PHONE_NUMBER_PLACEHOLDER } from './config';
import { PhoneInputProps } from './types';

const PhoneInput = (props: PhoneInputProps): JSX.Element => (
  <FormatInput format={PHONE_NUMBER_FORMAT} placeholder={PHONE_NUMBER_PLACEHOLDER} type="tel" {...props} />
);

export default PhoneInput;
