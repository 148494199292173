import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const DescriptionWrapper = styled.div(
  ({ theme: { spacing, typography } }: any) => css`
    margin-bottom: ${spacing(2)};
    display: flex;
    column-gap: ${spacing(1)};

    ${typography.t2};
  `,
);
