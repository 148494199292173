export enum Alignment {
  Stretch = 'stretch',
  Center = 'center',
  Start = 'flex-start',
  End = 'flex-end',
}

export type ButtonBlockProps = {
  blockAlignment: Alignment;
};
