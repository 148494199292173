import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const updatePublicFinancingRequestMutation = loader(
  '../../../graphql/mutations/calculator/updatePublicFinancingRequest.graphql',
);

const useUpdatePublicFinancingRequestMutation = () => {
  const [updatePublicFinancingRequest, mutationResult] = useMutation(updatePublicFinancingRequestMutation);

  return [updatePublicFinancingRequest, mutationResult] as MutationTuple<any, any>;
};

export default useUpdatePublicFinancingRequestMutation;
