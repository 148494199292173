const getMessage = (errors) =>
  Array.isArray(errors) ? errors.map((error) => error.message).join(' \n') : 'Unknown error';

export default class ErrorDecorator extends Error {
  errors = [];

  constructor(error) {
    const errors = ErrorDecorator.parse(error);
    super(getMessage(errors));
    this.errors = errors;
  }

  getMessages() {
    return this.errors.map((err) => err.message);
  }

  static parse(error) {
    if (error && typeof error === 'string') {
      return [new Error(error)];
    }

    return error.message ? [error] : [new Error('Something went wrong')];
  }
}
