import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

import { getScrollableMaterialStyles } from 'components/ui/atoms/Scrollable';

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-root': {
    background: theme.colors.white,
    color: theme.palette.text.primary,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.border}`,
  },

  '& .MuiSelect-select': {
    boxSizing: 'border-box',
    maxHeight: '73px',
    padding: theme.spacing(1, 4, 1, 1),
    overflowY: ({ multiple }) => (multiple ? 'auto' : 'hidden'),
    whiteSpace: ({ multiple }) => (multiple ? 'initial' : 'none'),

    '&.MuiSelect-outlined': {
      minHeight: '40px',
      ...theme.typography.input,
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.inputForMobile,
      },
    },

    ...getScrollableMaterialStyles(theme),
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border,
  },

  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border,
  },

  '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border,
  },

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },
  },

  '& .MuiSelect-icon': {
    top: '20%',
  },

  '&.Mui-disabled': {
    background: theme.palette.form.disabled.background,
    color: theme.palette.form.disabled.text,
    borderColor: theme.palette.form.disabled.border,
  },
}));
