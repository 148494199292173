import { UUID } from 'config/constants/queryParams';

import useAddingQueryParams from './query/useAddingQueryParams';

const useSaveDraftButtonClick = () => {
  const { addQueryParams } = useAddingQueryParams();

  const saveDraftButtonClick = ({ uuid }: { uuid: string }) => {
    addQueryParams({ [UUID]: uuid ?? null });
  };

  return { saveDraftButtonClick };
};

export default useSaveDraftButtonClick;
