import { useState } from 'react';

import Input from 'components/ui/atoms/forms/Input';

import { getCustomEvent, createEvent } from './helpers';
import type { BaseMaskInputProps, AcceptHandler, InputMask, EventHandler } from './types';
import CustomIMaskInput from './CustomIMaskInput';

const BaseMaskInput = ({
  value,
  name,
  onChange,
  onBlur,
  maskedOptions,
  nullable,
  unmask,
  ...props
}: BaseMaskInputProps) => {
  const [mask, setMask] = useState<InputMask | null>(null);

  const handleAccept: AcceptHandler = async (newValue, inputMask, event) => {
    const customEvent = getCustomEvent(event ?? createEvent(newValue, name ?? 'emptyName'), newValue);

    await onChange?.(customEvent, Boolean(event));
    setMask(inputMask);
  };

  const handleBlur: EventHandler = (event) => {
    const validValue = !mask ? value ?? '' : mask.unmaskedValue;
    onBlur?.(getCustomEvent(event, validValue, nullable));
  };

  return (
    <Input
      name={name}
      value={value}
      inputComponent={CustomIMaskInput}
      onBlur={handleBlur}
      inputProps={{
        unmask: unmask ?? true,
        onAccept: handleAccept,
        ...maskedOptions,
      }}
      {...props}
    />
  );
};

export default BaseMaskInput;
