import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ACCEPT_DOCUMENT_EXTENSIONS, ACCEPT_DOCUMENT_EXTENSIONS_CAPTION } from 'config/constants/fileFormats';

import FileUploader from 'components/ui/organisms/FileUploader';
import CaptionSmall from 'components/ui/atoms/text/CaptionSmall';
import PreviousDocuments from 'components/shared/molecules/PreviousDocuments';

import { DocumentsUploadFormProps } from './types';

const DetailsWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${spacing(1)};
  `,
);
const DocumentsUploadForm = ({
  setUploadedBankStatements,
  setIsDocumentsLoading,
  previousDocuments,
  label,
}: DocumentsUploadFormProps) => {
  const fileInputProps = {
    accept: ACCEPT_DOCUMENT_EXTENSIONS,
  };
  return (
    <DetailsWrapper>
      <CaptionSmall>{label}</CaptionSmall>
      <CaptionSmall>{ACCEPT_DOCUMENT_EXTENSIONS_CAPTION}</CaptionSmall>
      <FileUploader
        setUploaded={setUploadedBankStatements}
        multiple
        fileInputProps={fileInputProps}
        setLoading={setIsDocumentsLoading}
      />
      {previousDocuments && <PreviousDocuments documents={previousDocuments} columns={1} />}
    </DetailsWrapper>
  );
};

export default DocumentsUploadForm;
