import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import type { DropzoneProps } from './types';

const Wrapper = styled.div(
  ({ theme: { colors, spacing } }) => css`
    width: 100%;
    height: ${spacing(15)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${spacing(0.5)};
    border: 1px dashed ${colors.grayLight};
  `,
);

const BrowseLink = styled.a(
  ({ theme: { palette } }) => css`
    color: ${palette.primary.main};
  `,
);

const Dropzone = ({ onFilesChanged, text = 'Drop a file or', fileInputProps }: DropzoneProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => onFilesChanged(acceptedFiles), []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...fileInputProps,
  });

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <p>
        {text} <BrowseLink>browse</BrowseLink>
      </p>
    </Wrapper>
  );
};

export default Dropzone;
