import { Link } from 'react-router-dom';

import Logo from 'assets/icons/logo.svg';
import { APPLY } from 'config/routes';

import { HeaderContainer, HeaderLogo, HelperText, StyledLink } from './styled';
import { HeaderProps } from './types';

const Header = ({ brandedPage }: HeaderProps) => {
  if (!brandedPage) return null;

  return (
    <HeaderContainer>
      <Link to={APPLY}>
        <HeaderLogo src={Logo} alt="logo" />
      </Link>

      <HelperText>
        Questions? Call Us <StyledLink href="tel:2129316858">212-931-6858</StyledLink>
      </HelperText>
    </HeaderContainer>
  );
};

export default Header;
