import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';

import useCreateIntakeFormV2Mutation from 'api/mutations/intakeForm/useCreateIntakeFormV2Mutation';

import useNotifier from 'hooks/useNotifier';
import { UUID, STEP, ORIGIN } from 'config/constants/queryParams';
import { APPLY_FORM } from 'config/routes';
import { getApplyReferralIdFromOrigin } from 'utils/getApplyReferralIdFromOrigin';
import useReferralId from 'hooks/useReferralId';
import useAddingQueryParams from 'hooks/query/useAddingQueryParams';

import { INTAKE_FORM_FIRST_STEP } from '../../../../ApplyStepsForm/config';

const mutationName = 'createIntakeFormV2';

const useCreateIntakeForm = () => {
  const navigate = useNavigate();
  const { setError } = useNotifier();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get(ORIGIN);
  const userReferralId = useReferralId(getApplyReferralIdFromOrigin);

  const { getParamsWithAdded } = useAddingQueryParams();

  const [createIntakeFormMutation, { loading }] = useCreateIntakeFormV2Mutation({
    onCompleted: (data) => {
      const params = getParamsWithAdded({ [UUID]: data?.[mutationName]?.uuid ?? null, [STEP]: INTAKE_FORM_FIRST_STEP });

      const pathname = userReferralId ? `${APPLY_FORM}/${userReferralId}` : APPLY_FORM;
      navigate({ pathname, search: `?${createSearchParams(params)}` });
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  // TODO: use correct fields for this mutation
  const createIntakeForm = async ({ fields }: Record<string, any>) => {
    const variables = {
      ...fields,
      userReferralId,
      applicationFormSource: origin || window.location.href,
    };

    await createIntakeFormMutation({
      variables,
    });
  };

  return { createIntakeForm, loading };
};

export default useCreateIntakeForm;
