import { useEffect, useState } from 'react';

import SliderForm from '../SliderForm';
import ResultForm from '../ResultForm';
import { CalculatorValues } from './config';
import { FormContainerProps } from './types';
import { Wrapper, FormBlock } from './styled';

const FormContainer = ({ data, termUnitText, handleUpdate }: FormContainerProps) => {
  const { requestedFinancingAmount, requestedTermUnitSize } = data ?? {};

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [values, setValues] = useState({
    [CalculatorValues.RequestedFinancingAmountCents]: 0,
    [CalculatorValues.RequestedTermUnitSize]: 0,
  });

  useEffect(() => {
    if (requestedFinancingAmount && requestedTermUnitSize) {
      setValues((prevState) => {
        if (
          !prevState[CalculatorValues.RequestedFinancingAmountCents] &&
          !prevState[CalculatorValues.RequestedTermUnitSize]
        ) {
          // Set value only in first render when get data from query
          return {
            [CalculatorValues.RequestedFinancingAmountCents]: requestedFinancingAmount?.fractional,
            [CalculatorValues.RequestedTermUnitSize]: requestedTermUnitSize,
          };
        }
        return prevState;
      });
    }
  }, [requestedFinancingAmount, requestedTermUnitSize]);

  return (
    <Wrapper>
      <FormBlock>
        <SliderForm
          data={data}
          termUnitText={termUnitText}
          values={values}
          setValues={setValues}
          handleUpdate={handleUpdate}
          isSubmitted={isSubmitted}
        />
      </FormBlock>
      <FormBlock>
        <ResultForm
          data={data}
          termUnitText={termUnitText}
          values={values}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        />
      </FormBlock>
    </Wrapper>
  );
};

export default FormContainer;
