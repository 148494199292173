import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import useUpdateERCFormDraftMutation from 'api/mutations/erc/useUpdateERCFormDraftV2Mutation';
import useCreateERCFormDraftMutation from 'api/mutations/erc/useCreateERCFormDraftV2Mutation';

import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';
import useSaveDraftButtonClick from 'hooks/useSaveDraftButtonClick';
import { getErcReferralIdFromOrigin } from 'utils/getErcReferralIdFromOrigin';

import Typography from 'components/ui/atoms/Typography';
import Button from 'components/ui/atoms/buttons/Button';
import Checkbox, { CheckboxProps } from 'components/ui/atoms/forms/Checkbox';
import ConfirmationText from 'components/shared/organisms/form/ConfirmationText';

import { UUID, ORIGIN } from 'config/constants/queryParams';

import { saveForLaterValidationFields } from '../../../../config/config';

import { CallBackButtonBlock, DescriptionWrapper, AgreeBlock, ButtonBlock, ButtonWrapper } from './styled';

const CallBackButton = ({ validatedFields, isFormValuesChanged }: any) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isAgreeWithPolicy, setIsAgreeWithPolicy] = useState(true);

  const [searchParams] = useSearchParams();
  const referralId = useReferralId(getErcReferralIdFromOrigin);
  const formUuid = searchParams.get(UUID);
  const origin = searchParams.get(ORIGIN);

  const { saveDraftButtonClick } = useSaveDraftButtonClick();

  const { setSuccess, setError } = useNotifier();

  const requiredValidationSchema = Yup.object().shape(saveForLaterValidationFields);

  useEffect(() => {
    requiredValidationSchema
      .validate(validatedFields)
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false));
  }, [validatedFields]);

  const [updateERCFormDraft, { loading: updateERCLoading }] = useUpdateERCFormDraftMutation({
    onCompleted: () => {
      setSuccess('ERC form updated successfully!');
    },
    onError: (err) => setError(err),
  });

  const [createERCFormDraft, { loading }] = useCreateERCFormDraftMutation({
    onCompleted: (data) => {
      saveDraftButtonClick({ uuid: data?.uuid });
      setSuccess('ERC form draft was created successfully!');
    },
    onError: (gqlError) => {
      setError(`Form error: ${gqlError}`);
    },
  });

  const isDisabled = Boolean(!isValid || loading || updateERCLoading || !isFormValuesChanged || !isAgreeWithPolicy);

  const handleAgree: CheckboxProps['onChange'] = ({ currentTarget }) => setIsAgreeWithPolicy(currentTarget.checked);

  const clickHandler = async () => {
    if (isDisabled) {
      return;
    }

    const {
      businessName,
      businessEmployeesCount,
      merchantFirstName,
      merchantLastName,
      merchantEmail,
      merchantCellPhone,
    } = validatedFields ?? {};
    const variables = {
      userReferralId: referralId,
      businessName,
      businessEmployeesCount: +businessEmployeesCount,
      merchantFirstName,
      merchantLastName,
      merchantEmail,
      merchantCellPhone,
      savedForLater: true,
    };
    if (formUuid) {
      await updateERCFormDraft({
        variables: {
          uuid: formUuid,
          ...variables,
        },
      });
    } else {
      await createERCFormDraft({
        variables: {
          applicationFormSource: origin || window.location.href,
          ...variables,
        },
      });
    }
  };

  return (
    <CallBackButtonBlock>
      <DescriptionWrapper>
        <Typography variant="t2">
          Please fill in the information above to hear back from one of our specialists, or continue to the questions
          below to check your eligibility and get started with the ERC today.
        </Typography>
      </DescriptionWrapper>
      <ButtonBlock>
        <AgreeBlock>
          <Checkbox checked={isAgreeWithPolicy} onChange={handleAgree} name="agree-with-policy" />
          <ConfirmationText />
        </AgreeBlock>
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disableElevation
            disabled={isDisabled}
            onClick={clickHandler}
          >
            Request a call from a specialist
          </Button>
        </ButtonWrapper>
      </ButtonBlock>
    </CallBackButtonBlock>
  );
};

export default CallBackButton;
