import { useState } from 'react';
import FormLabel from '@mui/material/FormLabel';

import { ACCEPT_DOCUMENT_EXTENSIONS, ACCEPT_DOCUMENT_EXTENSIONS_CAPTION } from 'config/constants/fileFormats';

import Grid from 'components/templates/Grid';
import FileUploader from 'components/ui/organisms/FileUploader';
import UserAgreementText, { UserAgreementTextProps } from 'components/shared/molecules/UserAgreementText';
import PreviousDocuments from 'components/shared/molecules/PreviousDocuments/PreviosDocuments';
import CaptionMedium from 'components/ui/atoms/text/CaptionMedium';

import useSubmitForm from './useSubmitForm';
import { FormSubmitButton } from '../../styled';
import { Message } from './styled';
import { UploadDocumentsFormProps } from './types';
import useIntakeFormValues from '../../hooks/useIntakeFormValues';

const UploadDocumentsForm = ({ brandedPage }: UploadDocumentsFormProps) => {
  const [uploadedBankStatements, setUploadedBankStatements] = useState({});
  const [uploadedAdditionalDocs, setUploadedAdditionalDocs] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [isAgreeWithPolicy, setIsAgreeWithPolicy] = useState(true);

  const { data } = useIntakeFormValues();
  const { loading, createIntakeFormDocument, updateIntakeForm } = useSubmitForm();

  const handleAgree: UserAgreementTextProps['handleAgree'] = ({ currentTarget }) =>
    setIsAgreeWithPolicy(currentTarget.checked);

  const submitHandler = async () => {
    await createIntakeFormDocument({ uploadedBankStatements, uploadedAdditionalDocs });
    await updateIntakeForm();
  };

  const fileInputProps = {
    accept: ACCEPT_DOCUMENT_EXTENSIONS,
  };

  return (
    <Grid columns={1}>
      <>
        <FormLabel>Bank Statements</FormLabel>
        <FileUploader
          setUploaded={setUploadedBankStatements}
          multiple
          fileInputProps={fileInputProps}
          /* onFilesChange={updateIframe} */
          setLoading={setLoadingFiles}
        />
      </>
      <>
        <FormLabel>Additional Documents</FormLabel>
        <FileUploader
          setUploaded={setUploadedAdditionalDocs}
          multiple
          fileInputProps={fileInputProps}
          /* onFilesChange={updateIframe} */
          setLoading={setLoadingFiles}
        />
      </>
      <CaptionMedium>{ACCEPT_DOCUMENT_EXTENSIONS_CAPTION}</CaptionMedium>
      {data?.documents?.length > 0 && <PreviousDocuments documents={data?.documents} columns={1} />}
      <Message marginTop={6}>
        <UserAgreementText isAgreeWithPolicy={isAgreeWithPolicy} handleAgree={handleAgree} isBranded={brandedPage} />
      </Message>
      <FormSubmitButton
        onClick={submitHandler}
        disabled={loadingFiles || loading || !isAgreeWithPolicy}
        loading={loading}
        variant="contained"
        disableElevation
        margin={0}
      >
        Submit Data
      </FormSubmitButton>
    </Grid>
  );
};

export default UploadDocumentsForm;
