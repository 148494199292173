import styled from '@emotion/styled';

import { FontWeight } from 'types/theme/text';

import TitleLarge from 'components/ui/atoms/text/TitleLarge';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px auto;
  text-align: center;
`;

type SubmitMessageProps = {
  text?: string;
};

const SubmitMessage = ({ text }: SubmitMessageProps) => (
  <Container>
    <TitleLarge fontWeight={FontWeight.Bold}>{text || 'Thank you for applying!'}</TitleLarge>
  </Container>
);

export default SubmitMessage;
