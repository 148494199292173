import { AVAILABLE_CHARACTERS_ZIP_REGEXP } from '../regexp/zipCode';

const ZIP_MASK = 'F####[###]';
const ZIP_DEFINITIONS = {
  F: /^[A-Za-z\d]/,
  '#': AVAILABLE_CHARACTERS_ZIP_REGEXP,
};
export const ZIP_MASKED_OPTIONS = {
  mask: ZIP_MASK,
  definitions: ZIP_DEFINITIONS,
};
