import { Icons } from 'config/icons/feather';

import Icon from 'components/ui/atoms/Icon';

import type { AccordionProps } from './types';
import { Wrapper, StyledAccordion, SummaryContent, StyledAccordionSummary, StyledAccordionDetails } from './styled';

const Accordion = ({ children, title, defaultExpanded }: AccordionProps) => {
  return (
    <Wrapper>
      <StyledAccordion square defaultExpanded={defaultExpanded ?? true}>
        <SummaryContent>
          <StyledAccordionSummary expandIcon={<Icon type={Icons.ChevronDown} />}>{title}</StyledAccordionSummary>
        </SummaryContent>
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    </Wrapper>
  );
};

export default Accordion;
