import { toString } from 'lodash';

import type { OptionValue } from '../types';

const getOptionValue = <T extends Record<string, any>>(option: T, valueFieldName: string): OptionValue => {
  const value = valueFieldName in option ? option[valueFieldName] : 'UNKNOWN_VALUE';

  return typeof value === 'string' || typeof value === 'number' ? value : toString(value);
};

export default getOptionValue;
