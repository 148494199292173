import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div(
  ({ theme: { shape } }) => css`
    position: relative;
    overflow: hidden;
    border-radius: ${shape.borderRadius}px;
    z-index: 0;
    min-width: 376px;
  `,
);

export const Container = styled.div(
  ({ scheme, theme: { spacing } }) => css`
    background-color: ${scheme.bgColor};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)};
  `,
);

export const Text = styled.span(
  ({ scheme, theme: { typography, spacing } }) => css`
    padding-left: ${spacing(1)};
    color: ${scheme.color};
    ${typography.notify};
  `,
);

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled(TextWrapper)(
  ({ theme: { spacing } }) => css`
    margin-left: ${spacing(3)};
  `,
);
