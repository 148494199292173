import DateInput from 'components/ui/atoms/forms/DateInput';

export const AboutYourBusiness = [
  {
    id: 'businessStartedAt',
    name: 'businessStartedAt',
    label: 'When was your business started?',
    control: <DateInput inputFormat="MM/DD/YYYY" required disableFuture />,
    required: true,
  },
];
