import PageTitle from 'components/ui/atoms/text/PageTitle';

import { Container } from './styled';

const Header = () => (
  <Container>
    <PageTitle>Resubscribe</PageTitle>
    <p>
      Please remove me from your Do Not Call list. I wish to resubscribe and receive communications from Premium
      Merchant Funding.
    </p>
  </Container>
);

export default Header;
