import CustomNumberFormat from 'components/ui/atoms/CustomNumberFormat';
import Input from 'components/ui/atoms/forms/Input';

import type { MoneyInputProps, ChangeEventHandler, BlurEventHandler } from './types';
import { transformToValidValue, getEvent, getDisplayedValue, getExportedValue } from './helpers';
import { DOLLAR_PREFIX, DECIMAL_COUNT } from './config';

// work with cents but display dollars
const MoneyInput = ({
  value,
  onChange,
  onBlur,
  isNoneNullable,
  allowNegative,
  isNumericString,
  thousandSeparator,
  prefix,
  decimalScale,
  ...props
}: MoneyInputProps): JSX.Element => {
  const handleChange: ChangeEventHandler = (formatValues, { event }) => {
    if (!event) return;

    const exportedValue = getExportedValue(transformToValidValue(formatValues.floatValue));
    onChange?.(getEvent(event, exportedValue, isNoneNullable));
  };

  const handleBlur: BlurEventHandler = (event) => {
    onBlur?.(getEvent(event, transformToValidValue(value), isNoneNullable));
  };

  return (
    <Input
      value={getDisplayedValue(value)}
      onBlur={handleBlur}
      inputComponent={CustomNumberFormat}
      inputProps={{
        onValueChange: handleChange,
        isNumericString: isNumericString ?? true,
        thousandSeparator: thousandSeparator ?? true,
        prefix: prefix ?? DOLLAR_PREFIX,
        decimalScale: decimalScale ?? DECIMAL_COUNT,
        allowNegative: allowNegative ?? false,
      }}
      {...props}
    />
  );
};

export default MoneyInput;
