export const DEFAULT_TEXT =
  'Please be advised that the estimate generated by the financing calculator is based on the information provided and is subject to change. ' +
  'Various factors, including market conditions and funder policies, may influence the final financing terms.';
export const SUBMITTED_TEXT =
  'Your request was sent to your funding specialist who will get in touch you shortly. Thank you for your trust in Premium Merchant Funding.';

export enum FinancingRequestStatus {
  Requested = 'REQUESTED',
  Draft = 'DRAFT',
}
