import styled from '@emotion/styled';
import { css } from '@emotion/react';

import PageTitle from 'components/ui/atoms/text/PageTitle';

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const TextWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
  `,
);

const Header = () => (
  <Container>
    <PageTitle>Credit Card Processing</PageTitle>
    <TextWrapper>
      <p>Thank you for placing your trust in Premium Merchant Funding.</p>
      <p>
        Please complete the form below, and a specialist will be in touch shorty. We look forward to saving you money on
        your credit card processing.
      </p>
    </TextWrapper>
  </Container>
);

export default Header;
