import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useCreateIntakeFormDocumentV2Mutation from 'api/mutations/intakeForm/useCreateIntakeFormDocumentV2Mutation';
import { DocumentGroups } from 'graphql/types/enums/documentGroups';
import { UUID } from 'config/constants/queryParams';
import useNotifier from 'hooks/useNotifier';
import { UploadedFile } from 'components/ui/molecules/File/types';

type CreateIntakeFormDocumentArgs = {
  uploadedBankStatements: Record<string, UploadedFile>;
  uploadedAdditionalDocs: Record<string, UploadedFile>;
};

type GetIntakeFormDocumentVariablesArgs = {
  files: Record<string, UploadedFile>;
  uuid: string | null;
  group: any; // TODO: use DocumentGroup enum
  name?: string;
};

const getIntakeFormDocumentVariables = ({ files, group, name, uuid }: GetIntakeFormDocumentVariablesArgs) =>
  Object.values(files).map((documentTypeFile) => ({
    file: documentTypeFile,
    uuid,
    group,
    name,
  }));

const useCreateIntakeFormDocument = () => {
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const { setError } = useNotifier();

  const [createIntakeFormDocumentMutation, { loading }] = useCreateIntakeFormDocumentV2Mutation({
    onCompleted: () => {},
    onError: (error) => {
      setIsError(true);
      setError(`Form error: ${error}`);
    },
  });

  const createIntakeFormDocument = async ({
    uploadedBankStatements,
    uploadedAdditionalDocs,
  }: CreateIntakeFormDocumentArgs) => {
    if (!Object.values({ ...uploadedBankStatements, ...uploadedAdditionalDocs }).length) {
      return;
    }

    const config = {
      [DocumentGroups.BankStatement]: {
        files: uploadedBankStatements,
        group: DocumentGroups.BankStatement,
        name: 'Bank Statement',
        uuid,
      },
      [DocumentGroups.Other]: {
        files: uploadedAdditionalDocs,
        group: DocumentGroups.Other,
        name: 'Other',
        uuid,
      },
    };

    await Promise.all(
      Object.values(config)
        .flatMap((documentTypeConfig) => getIntakeFormDocumentVariables({ ...documentTypeConfig, uuid }))
        .map(async (variables) => {
          await createIntakeFormDocumentMutation({
            variables,
          });
        }),
    );
  };

  return { createIntakeFormDocument, loading, isError };
};

export default useCreateIntakeFormDocument;
