import { useMemo, useState, ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import useValidation from 'lib/hooks/validation/useValidation';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import openThirdPartyLink from 'utils/openThirdPartyLink';
import { THANK_YOU_PAGE_URL } from 'config/constants/queryParams';

import FormSection from 'components/shared/organisms/form/FormSection';
import FormFooter, { Alignment } from 'components/shared/organisms/form/FormFooter';
import SubmitMessage from 'components/shared/organisms/form/SubmitMessage';
import RedirectingMessage from 'components/ui/atoms/messages/RedirectingMessage';
import Loader from 'components/ui/molecules/Loader';
import Accordion from 'components/ui/organisms/Accordion';
import DocumentsUploadForm from 'components/shared/organisms/form/DocumentsUploadForm';

import { requiredValidationFields, getContactInformationFields } from './config';
import useCreateOneStepForm, { OnCompleted } from './hooks/useCreateOneStepForm';
import useOneStepFormValues from './hooks/useOneStepFormValues';

const AGREE_TEXT =
  'By submitting this form, I consent to receive communication at the contact information provided above';

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showRedirecting, setShowRedirecting] = useState(false);
  const [onCompleted, setOnCompleted] = useState<OnCompleted>(undefined);

  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [uploadedBankStatements, setUploadedBankStatements] = useState({});
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);

  const isMobile = useCheckMobileScreen();

  const [searchParams] = useSearchParams();
  const thankYouPage = searchParams.get(THANK_YOU_PAGE_URL);

  const formConfig = {
    columns: isMobile ? 1 : 2,
    withHiddenFields: false,
    formFields: getContactInformationFields(isMobile),
  };

  const { data: formValues, loading: formValuesLoading } = useOneStepFormValues();

  const {
    autoValidation: autoRequiredValidation,
    fields,
    isValid,
    ...formProps
  } = useValidation({ fields: formValues }, true);

  const requiredValidationSchema = useMemo(() => Yup.object().shape(requiredValidationFields), [fields]);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (!isFormValuesChanged) setIsFormValuesChanged(true);
    autoRequiredValidation(requiredValidationSchema, name, value);
  };

  const { createForm, loading } = useCreateOneStepForm({ setIsSubmitted, onCompleted });

  const handleSubmit = async () => {
    setOnCompleted(() => {
      if (thankYouPage) {
        const { openLink, isSameDomainLink } = openThirdPartyLink(thankYouPage);
        openLink();
        if (isSameDomainLink) setShowRedirecting(true);
        else setIsSubmitted(true);
      }
    });

    try {
      await createForm({ fields, uploadedBankStatements });
    } catch (error) {
      console.error(error);
    }
  };

  if (showRedirecting)
    return (
      <Loader>
        <RedirectingMessage />
      </Loader>
    );

  if (isSubmitted) return <SubmitMessage />;

  return (
    <form id="one-step-form">
      <FormSection
        title="Contact Information"
        validatedFields={fields}
        handleChange={handleChange}
        isFormValuesChanged={isFormValuesChanged}
        {...formConfig}
        {...formProps}
      />
      <Accordion defaultExpanded={false} title="Upload your bank statements now to speed long your approval (Optional)">
        <DocumentsUploadForm
          setUploadedBankStatements={setUploadedBankStatements}
          setIsDocumentsLoading={setIsDocumentsLoading}
          label="Please include last 4 month of business bank statements"
          previousDocuments={formValues.documents}
        />
      </Accordion>

      <FormFooter
        disabled={loading || !isValid || isDocumentsLoading || formValuesLoading}
        submitHandler={handleSubmit}
        buttonConfirmationText={AGREE_TEXT}
        buttonText="Submit"
        footerAlignment={Alignment.Stretch}
      />
    </form>
  );
};

export default Form;
