import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SliderWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    margin-top: ${spacing(2)};
  `,
);
