import PageTitle from 'components/ui/atoms/text/PageTitle';

import { INTAKE_FORM_STEPS, STEPS_TITLES } from '../../config';
import { Subtitle, TitlesWrapper } from './styled';
import { TitleProps } from './types';

const Title = ({ step, brandedPage }: TitleProps) => {
  if (!step) return null;

  const { title, subtitle, icon } = STEPS_TITLES[step as INTAKE_FORM_STEPS] ?? {};

  return (
    <TitlesWrapper>
      {icon && icon}
      {title && <PageTitle>{title}</PageTitle>}
      {subtitle && <Subtitle>{typeof subtitle === 'function' ? subtitle({ brandedPage }) : subtitle}</Subtitle>}
    </TitlesWrapper>
  );
};

export default Title;
