import FormatInput from 'components/ui/atoms/forms/FormatInput';

import { SSN_LAST_4_FORMAT, SSN_LAST_4_PLACEHOLDER } from './config';
import type SSNLast4InputProps from './types';

const SSNLast4 = (props: SSNLast4InputProps): JSX.Element => (
  <FormatInput format={SSN_LAST_4_FORMAT} placeholder={SSN_LAST_4_PLACEHOLDER} {...props} />
);

export default SSNLast4;
