import { ERCGeneralInformationFields } from '../components/form/formSection/fields/ERCGeneralInformationFields';
import { PppQuestionsFields } from '../components/form/formSection/fields/PppQuestionsFields';
import { AboutYourBusiness } from '../components/form/formSection/fields/AboutYourBusiness';
import { GrossReceipts2021 } from '../components/form/formSection/fields/GrossReceipts2021';
import { GrossReceipts2020 } from '../components/form/formSection/fields/GrossReceipts2020';
import { GrossReceipts2019 } from '../components/form/formSection/fields/GrossReceipts2019';

import CallBackButton from '../components/form/formSection/ERCGeneralInformation/CallBackButton';

const ercFormFields = [
  {
    id: 'generalOverview',
    columns: 2,
    title: 'General Overview',
    withHiddenFields: false,
    formFields: ERCGeneralInformationFields,
    Footer: CallBackButton,
  },
  {
    id: 'pppQuestions',
    columns: 1,
    title: 'PPP Questions',
    withHiddenFields: true,
    formFields: PppQuestionsFields,
  },
  {
    id: 'aboutYourBusiness',
    columns: 1,
    title: 'About Your Business',
    withHiddenFields: false,
    formFields: AboutYourBusiness,
  },
  {
    id: 'GrossReceipts2021',
    title: '2021 gross receipts',
    columns: 2,
    withHiddenFields: false,
    formFields: GrossReceipts2021,
    preface:
      'Gross receipts are your total sales less returns and allowances. It also includes interest, dividends, rents, royalties and gains on sales of assets. It should NOT be reduced by cost of goods sold. We are awaiting rules to determine what method of accounting should be used, cash, accrual or otherwise. For now, simply use the method of accounting that is used on your tax reporting. For most taxpayers that is "Cash Basis".',
  },
  {
    id: 'GrossReceipts2020',
    title: '2020 gross receipts',
    columns: 2,
    withHiddenFields: false,
    formFields: GrossReceipts2020,
  },
  {
    id: 'GrossReceipts2019',
    title: '2019 gross receipts',
    columns: 2,
    withHiddenFields: false,
    formFields: GrossReceipts2019,
  },
];

export default ercFormFields;
