import styled from '@emotion/styled';

import LogoImage from 'assets/icons/logoBlue.svg';

const StyledImage = styled.img`
  width: 110px;
`;

const Logo = () => <StyledImage src={LogoImage} alt="logo" />;

export default Logo;
