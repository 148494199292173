import { ButtonTypes } from '../types';
import { BaseIconButtonProps } from './types';
import IconButtonWrapper from './IconButtonWrapper';
import StyledIconButton from './styled';

const BaseIconButton = ({ title, type, disabled, onClick, stroke, children, ...restProps }: BaseIconButtonProps) => (
  <IconButtonWrapper title={title} disabled={disabled}>
    <StyledIconButton
      type={type ?? ButtonTypes.Button}
      disabled={disabled ?? false}
      onClick={onClick}
      stroke={stroke}
      {...restProps}
    >
      {children}
    </StyledIconButton>
  </IconButtonWrapper>
);

export default BaseIconButton;
