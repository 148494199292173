import Notifier from './Notifier';

class Notifiers {
  notifierList = new Map();

  set(notifier) {
    const newNotifier = new Notifier(notifier);

    this.notifierList.set(newNotifier.id, newNotifier);
  }

  remove(id) {
    return this.notifierList.delete(id);
  }

  get newList() {
    return [...this.notifierList.values()];
  }
}

export default Notifiers;
