import Box from '@mui/material/Box';

import { DocumentGroup } from 'api/__generated__/enums';
import { PublicDocument } from 'api/__generated__/types';

import { Icons } from 'config/icons/feather';

import Typography from 'components/ui/atoms/Typography';
import Alert from 'components/ui/molecules/Alert';
import { GridContainer, GridElement } from 'components/templates/Grid';
import { AlertTypes } from 'components/ui/molecules/Alert/types';
import { GridContainerProps } from 'components/templates/Grid/types';

const PreviousDocument = ({ ...document }) => {
  const { name, file } = document || {};
  const { metadata, url } = file || {};
  const { filename } = metadata || {};

  return (
    <Box my={1}>
      <a rel="noreferrer" target="_blank" href={url}>
        <Alert type={AlertTypes.Info} iconType={Icons.File}>
          {name} - {filename}
        </Alert>
      </a>
    </Box>
  );
};

type PreviousDocumentsProps = Pick<GridContainerProps, 'columns'> & {
  documents: PublicDocument[];
};

const PreviousDocuments = ({ columns, documents }: PreviousDocumentsProps) => {
  return (
    documents?.length > 0 && (
      <>
        <Box mb={1} mt={2}>
          <Typography variant="t1">Previously added documents:</Typography>
        </Box>
        <GridContainer data-testid="chosen-documents" columns={columns}>
          <GridElement data-testid="chosen-document" columns={columns}>
            {documents.map((document) =>
              document.group === DocumentGroup.BankStatement ? (
                <PreviousDocument key={document.id} {...document} />
              ) : null,
            )}
          </GridElement>
          <GridElement data-testid="chosen-document" columns={columns}>
            {documents.map((document) =>
              document.group === DocumentGroup.Other ? <PreviousDocument key={document.id} {...document} /> : null,
            )}
          </GridElement>
        </GridContainer>
      </>
    )
  );
};

export default PreviousDocuments;
