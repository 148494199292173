import { useState, cloneElement, useEffect, ChangeEvent } from 'react';

import Label from 'components/ui/molecules/FormControl/components/Label';
import Input from 'components/ui/atoms/forms/Input';
import Slider, { SliderValue } from 'components/ui/atoms/Slider';

import { SliderWithInputProps } from './types';
import { SliderWrapper, Wrapper } from './styled';

const SliderWithInput = ({
  label,
  onChange,
  control,
  max = 100,
  min = 0,
  value,
  id,
  disabled,
  onChangeCommitted,
  ...props
}: SliderWithInputProps) => {
  const [sliderValue, setSliderValue] = useState(value ?? min);

  useEffect(() => {
    if (value) {
      setSliderValue(value);
    }
  }, [value]);

  const handleChange = (newValue: SliderValue) => {
    setSliderValue(newValue);
    onChange?.(newValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const typedValue = event.target.value;
    const newValue = typedValue === '' ? 0 : Number(typedValue);
    if (!Number.isNaN(newValue)) {
      handleChange(newValue);
    }
  };

  const handleSliderChange = (event: Event, newValue: SliderValue) => {
    handleChange(newValue);
  };

  const handleBlur = () => {
    if (sliderValue < min) {
      handleChange(min);
    } else if (sliderValue > max) {
      handleChange(max);
    }

    onChangeCommitted?.();
  };

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      {control ? (
        cloneElement(control, { value: sliderValue, id, onChange: handleInputChange, onBlur: handleBlur, disabled })
      ) : (
        // @ts-expect-error fix handleInputChange type
        <Input id={id} value={sliderValue} onChange={handleInputChange} onBlur={handleBlur} disabled={disabled} />
      )}
      <SliderWrapper>
        <Slider
          max={max}
          min={min}
          value={sliderValue}
          onChange={handleSliderChange}
          disabled={disabled}
          onChangeCommitted={onChangeCommitted}
          {...props}
        />
      </SliderWrapper>
    </Wrapper>
  );
};

export default SliderWithInput;
