import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const updateERCFormDraftV2Mutation = loader('../../../graphql/mutations/erc/updateERCFormDraftV2.graphql');

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};
// TODO type props, type return result
const useUpdateERCFormDraftMutation = ({ onCompleted, onError }: Props) => {
  const [updateERCFormDraft, mutationResult] = useMutation(updateERCFormDraftV2Mutation, {
    onCompleted: (data) => onCompleted(data?.updateErcFormV2 ?? {}),
    onError: (error) => onError(error),
  });

  return [updateERCFormDraft, mutationResult] as MutationTuple<any, any>;
};

export default useUpdateERCFormDraftMutation;
