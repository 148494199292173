import Logo from './Logo';
import { Wrapper } from './styled';

const Header = () => {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
};

export default Header;
