import { useSearchParams } from 'react-router-dom';

import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';
import { getCcpReferralIdFromOrigin } from 'utils/getCcpFormReferralIdFromOrigin';
import { ORIGIN } from 'config/constants/queryParams';

import useCreateCcpFormMutation from 'api/mutations/ccpForm/useCreateCcpFormMutation';
import { DocumentGroups } from 'graphql/types/enums/documentGroups';

import { UseCreateCcpFormProps, CreateFormProps } from '../types';

const useCreateCCPForm = ({ setIsSubmitted, onCompleted }: UseCreateCcpFormProps) => {
  const { setError, setSuccess } = useNotifier();

  const [searchParams] = useSearchParams();
  const referralId = useReferralId(getCcpReferralIdFromOrigin);
  const origin = searchParams.get(ORIGIN);

  const [createCcpForm, { loading }] = useCreateCcpFormMutation({
    onCompleted: () => {
      setSuccess('Short application form was submitted successfully!');
      setIsSubmitted(true);
      onCompleted?.();
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  const createForm = async ({ fields, uploadedBankStatements }: CreateFormProps) => {
    const documents = Object.values(uploadedBankStatements).map((file) => ({
      file,
      group: DocumentGroups.BankStatement,
      name: 'Bank Statement',
    }));

    await createCcpForm({
      variables: {
        ...fields,
        userReferralId: referralId,
        applicationFormSource: origin || window.location.href,
        documentsAttributes: documents,
      },
    });
  };

  return { createForm, loading };
};

export default useCreateCCPForm;
