// class as type
export class ApolloClientError {
  errors = [];

  fieldErrors = {};

  generalErrors = [];

  constructor(fieldErrors, generalErrors, errors) {
    this.errors = errors;
    this.fieldErrors = fieldErrors;
    this.generalErrors = generalErrors;
  }
}
