export type TypographyKeys =
  | 'h1'
  | 'h2'
  | 'h3'
  | 't1'
  | 't2'
  | 't3'
  | 't4'
  | 'title'
  | 'subtitle'
  | 'subtitleBold'
  | 'nav'
  | 'input'
  | 'inputForMobile'
  | 'button'
  | 'alert'
  | 'notify';

export interface ITypographyVariants {
  fontSize: number;
  lineHeight?: string;
  fontWeight?: number;
  [key: string]: string | number | undefined;
}

export type Typography = { [key in TypographyKeys]: ITypographyVariants };

export const H1 = {
  fontSize: 32,
  lineHeight: '48px',
  fontWeight: 700,
} as const;

export const H2 = {
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 700,
} as const;

const typography = {
  h1: H1,
  h2: H2,
  h3: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 700,
  },
  t1: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
  },
  t2: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
  },
  t3: {
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 400,
  },
  t4: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 400,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
  },
  nav: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 600,
  },
  input: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 600,
  },
  inputForMobile: {
    fontSize: 16,
  },
  button: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 600,
  },
  alert: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 600,
  },
  notify: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
  },
} as const;

export default typography;
