import Icon, { Icons } from 'components/ui/atoms/Icon';

type SelectIconProps = {
  className?: string;
};

const SelectIcon = ({ className }: SelectIconProps) => {
  return (
    <i className={`material-icons ${className}`}>
      <Icon type={Icons.ChevronDown} />
    </i>
  );
};

export default SelectIcon;
