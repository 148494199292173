import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Icon from 'components/ui/atoms/Icon';
import { Disabled } from './types';

export const DefaultCheckbox = styled.input<Disabled>(
  ({
    theme: {
      palette: {
        form: {
          active,
          disabled: { background: disabledBackground },
        },
      },
    },
    disabled,
  }) => css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: ${disabled ? 'default' : 'pointer'};

    &:checked + span {
      border: 2px solid ${disabled ? disabledBackground : active};
      background-color: ${disabled ? disabledBackground : active};
    }

    &:indeterminate + span {
      border: 2px solid ${active};
      background-color: ${active};
    }
  `,
);

export const StyledCheckbox = styled.span<Disabled>(
  ({
    theme: {
      palette: {
        form: {
          main,
          disabled: { border: disabledBorder, background: disabledBackground, text: disabledText },
        },
      },
      colors: { white },
      spacing,
    },
    disabled,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    max-width: ${spacing(2.2)};
    width: ${spacing(2.2)};
    height: ${spacing(2.2)};
    color: ${disabled ? disabledText : white};
    border: 2px solid ${disabled ? disabledBorder : main};
    background-color: ${disabled ? disabledBackground : 'transparent'};
    border-radius: 2px;
  `,
);

export const StyledFormControlLabel = styled.label<Disabled>(
  ({ theme: { palette, typography, spacing }, disabled }) => css`
    position: relative;
    margin: 0;
    width: max-content;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    cursor: ${disabled ? 'default' : 'pointer'};
    color: ${disabled ? palette.form.disabled.text : palette.text.primary};
    gap: ${spacing(1)};
    ${typography.t2};
  `,
);

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  stroke-width: 3;
`;

export const StyledSpan = styled.span`
  white-space: pre-line;
`;
