import { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';

import { Icons } from 'config/icons/feather';

export enum AlertTypes {
  Warn = 'warn',
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Primary = 'primary',
}

export type AlertProps = {
  theme: Theme;
  children: ReactNode;
  type: AlertTypes;
  action?: () => void;
  iconType?: Icons;
  pending?: boolean;
  withIcon?: boolean;
  noWrap?: boolean;
};

export type Schema = {
  color: string;
  bgColor: string;
  icon: Icons;
  iconStroke: string;
};

export type AlertTextProps = Pick<AlertProps, 'noWrap'> & {
  schema: Schema;
};

export type AlertContainerProps = Pick<AlertProps, 'pending'> & {
  schema: Schema;
};

export type PendingAnimationProps = {
  schema: Schema;
};
