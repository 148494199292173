import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { CustomTestType } from 'types/testType';
import { TextCase, Margin, FontWeight } from 'types/theme/text';
import { TextType } from '../types';

type TitleMediumProps = CustomTestType & TextType;

export const StyledTitle = styled.h3<Pick<TitleMediumProps, 'disabled' | 'textCase' | 'marginBottom' | 'fontWeight'>>(
  ({ theme: { spacing, palette, typography }, disabled, textCase, marginBottom, fontWeight }) => css`
    ${typography.title};
    margin: 0 0 ${spacing(marginBottom ?? Margin.Medium)};
    text-transform: ${textCase ?? TextCase.None};
    color: ${disabled ? palette.text.disabled : palette.text.primary};
    font-weight: ${fontWeight ?? FontWeight.Normal};
  `,
);

const TitleMedium = ({ children, disabled, textCase, marginBottom, fontWeight, dataTestId }: TitleMediumProps) => (
  <StyledTitle
    disabled={disabled}
    textCase={textCase}
    marginBottom={marginBottom}
    fontWeight={fontWeight}
    data-testid={dataTestId}
  >
    {children}
  </StyledTitle>
);

export default TitleMedium;
