import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { STEP, UUID } from 'config/constants/queryParams';
import { APPLY } from 'config/routes';

import { INTAKE_FORM_STEPS } from '../config';

export const isValidStep = (step: string | null) =>
  !step ? false : Object.values(INTAKE_FORM_STEPS).includes(step as INTAKE_FORM_STEPS);
export const getStepNumber = (step: string | null) =>
  !step ? 0 : Object.values(INTAKE_FORM_STEPS).indexOf(step as INTAKE_FORM_STEPS);
export const getCurrentStep = (step: string | null) => (step && isValidStep(step) ? step : INTAKE_FORM_STEPS.Step1);

const useRedirect = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get(STEP);
  const uuid = searchParams.get(UUID);

  const activeStepNumber = getStepNumber(step);

  useEffect(() => {
    if (uuid) {
      if (!isValidStep(step)) {
        const currentStep = getCurrentStep(step);
        setSearchParams({ [UUID]: uuid ?? null, [STEP]: currentStep });
      }
    } else {
      navigate({ pathname: APPLY });
    }
  }, [step, uuid]);

  return { activeStepNumber, step };
};

export default useRedirect;
