import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const CalculatorContainerWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${spacing(10, 4, 7)};
    row-gap: ${spacing(6)};
  `,
);
