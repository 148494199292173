import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ERC } from 'config/routes';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => navigate(ERC), []);

  return null;
};

export default Home;
