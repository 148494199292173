import FormatInput from 'components/ui/atoms/forms/FormatInput';

import { IDENTIFICATION_NUMBER_FORMAT } from './config';
import IdentificationNumberInputProps from './types';

const IdentificationNumberInput = (props: IdentificationNumberInputProps): JSX.Element => (
  <FormatInput format={IDENTIFICATION_NUMBER_FORMAT} {...props} />
);

export default IdentificationNumberInput;
