import { useState, useEffect } from 'react';

import { dataURLtoFile } from 'lib/utils/file';
import useUpload from 'hooks/useUpload';

import ErrorDecorator from 'decorators/ErrorDecorator';

import { OWNER, PARTNER, Sign, HolderTypes } from 'components/shared/molecules/Signature';

import { UseSignatureArgs } from './types';
import { getVariables, getSignatures } from './helpers';

// TODO: can move as global hook (when we will create component in shared repo)
const useSignature = ({ withPartner }: UseSignatureArgs) => {
  const [uploading, setUploading] = useState(false);
  const [signature, setSignature] = useState(getSignatures(withPartner));
  const { upload } = useUpload({ showGeneralError: false });

  const isSignatureChange = Boolean(signature[OWNER] || signature[PARTNER]);

  const validDraftOwnerSignature = Boolean(signature[OWNER]);
  const validDraftPartnerSignature = (withPartner && Boolean(signature[PARTNER])) || !withPartner;

  useEffect(() => {
    setSignature(({ [OWNER]: owner, [PARTNER]: partner }) => getSignatures(withPartner, owner, partner));
  }, [withPartner]);

  const uploadSignature = async () => {
    try {
      setUploading(true);
      let mutationVariables = {};
      const signatures = Object.entries(signature).filter(([_, signatureValue]) => Boolean(signatureValue));

      await Promise.all(
        signatures.map(async ([signatureKey, signatureValue]) => {
          const uploaded = await upload(dataURLtoFile(signatureValue, `${signatureKey}Signature`));
          mutationVariables = {
            ...mutationVariables,
            // @ts-expect-error remove after add types to useUpload hook
            ...getVariables(uploaded, signatureKey),
          };
        }),
      );

      return mutationVariables;
    } catch (error) {
      console.error(new ErrorDecorator(error).getMessages()[0]);
    } finally {
      setUploading(false);
    }

    return {};
  };

  const setSignatureHandle = (value: Sign, signatureHolder: HolderTypes) => {
    setSignature((prev) => ({
      ...prev,
      [signatureHolder]: value,
    }));
  };

  return {
    signature,
    setSignature: setSignatureHandle,
    isSignatureChange,
    uploading,
    validDraftOwnerSignature,
    validDraftPartnerSignature,
    uploadSignature,
  };
};

export default useSignature;
