import { ReactNode, MouseEvent as ReactMouseEvent } from 'react';
import { styled } from '@mui/material/styles';

import Chip from 'components/ui/atoms/Chip';

import type { RenderValueParams } from '../types';
import type { Option } from '../../models';

export const StyledChip = styled(Chip)(({ theme: { spacing, typography } }) => ({
  ...typography.t2,
  margin: spacing(0.125, 0.5),
  zIndex: 1,
}));

export type SelectValueProps = {
  label: ReactNode;
  disabled?: boolean;
  onMouseDown?: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete?: (event: any) => void;
};

const SelectValue = ({ label, onDelete, disabled, onMouseDown }: SelectValueProps) => {
  return (
    <StyledChip
      label={label}
      size="small"
      variant="outlined"
      onDelete={onDelete}
      onMouseDown={onMouseDown}
      disabled={disabled}
      clickable
    />
  );
};

export const renderValue = <TV extends string, TL extends string, T extends Option<TV, TL>>({
  selectedValue,
  handleDelete,
  disabled,
  model,
}: RenderValueParams<TV, TL, T>) => {
  const onMouseDown = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return selectedValue.map((value) => {
    const option = model.findOption(model.options, value);

    return (
      <SelectValue
        key={value}
        label={option ? model.getOptionLabel(option) : value}
        onDelete={() => handleDelete(value)}
        disabled={disabled}
        onMouseDown={onMouseDown}
      />
    );
  });
};
