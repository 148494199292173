import MoneyInput from 'components/ui/atoms/forms/MoneyInput';

export const GrossReceipts2019 = [
  {
    id: 'grossReceipts2019Q1Cents',
    name: 'grossReceipts2019Q1Cents',
    label: '2019 Q1 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2019Q2Cents',
    name: 'grossReceipts2019Q2Cents',
    label: '2019 Q2 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2019Q3Cents',
    name: 'grossReceipts2019Q3Cents',
    label: '2019 Q3 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
  {
    id: 'grossReceipts2019Q4Cents',
    name: 'grossReceipts2019Q4Cents',
    label: '2019 Q4 gross receipts?',
    placeholder: 'Enter number',
    control: <MoneyInput />,
    required: true,
  },
];
