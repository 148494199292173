import { forwardRef } from 'react';

import type { SelectProps } from './types';
import type { Option } from './models';

import BaseSelect from './BaseSelect';
import MultipleSelect from './MultipleSelect';

const Select = <TV extends string, TL extends string, T extends Option<TV, TL>>(
  { multiple, ...props }: SelectProps<TV, TL, T>,
  ref: any,
) => {
  return multiple ? <MultipleSelect ref={ref} multiple {...props} /> : <BaseSelect ref={ref} {...props} />;
};

export default forwardRef(Select);
