import type { SelectOptionsProps, SelectOptionProps, BaseOption } from './types';
import { EMPTY_OPTION } from './config';
import StyledMenuItem from './styled';

const getSelectOption = ({ label, value, ...props }: SelectOptionProps): JSX.Element => {
  return (
    <StyledMenuItem key={value} value={value} {...props}>
      {label}
    </StyledMenuItem>
  );
};

const getSelectOptions = <T extends BaseOption>({
  options,
  getOptionLabel,
  getOptionValue,
}: SelectOptionsProps<T>): JSX.Element[] => {
  if (!options?.length) {
    return [getSelectOption(EMPTY_OPTION)];
  }

  return options.map((option) =>
    getSelectOption({ value: getOptionValue(option), label: getOptionLabel(option), disabled: option?.disabled }),
  );
};

export default getSelectOptions;
