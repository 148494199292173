import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { GridElementProps, GridContainerProps } from './types';
import { DEFAULT_COLUMNS, DEFAULT_GAP } from './config';

export const GridContainer = styled.div<GridContainerProps>(
  ({
    gap = DEFAULT_GAP,
    theme: {
      numberSpacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin: 0 -${numberSpacing(gap) / 2}px;

    ${down(breakpoints.xs)} {
      margin: 0;
    }
  `,
);

export const GridElement = styled.div<GridElementProps>(
  ({
    gap = DEFAULT_GAP,
    columns = DEFAULT_COLUMNS,
    theme: {
      spacing,
      numberSpacing,
      breakpoints: { values: breakpoints, down },
    },
    customStyles,
  }) => css`
    /* one column */
    min-width: 0;
    margin: 0 ${numberSpacing(gap) / 2}px ${spacing(gap)};
    /* check 100% for old browsers, if not works use \`calc(99.99% / ${columns} - ${spacing(gap)});\` */
    width: ${columns > 1 ? `calc(100% / ${columns} - ${spacing(gap)});` : `100%`};

    ${down(breakpoints.xs)} {
      margin: 0 0 ${spacing(gap)};
      width: 100%;
    }

    ${customStyles && customStyles(spacing)}
  `,
);
