import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Margin } from 'types/theme/text';

import { GridContainer } from 'components/templates/Grid';
import GridFormElement from 'components/ui/molecules/GridFormElement';
import CaptionSmall from 'components/ui/atoms/text/CaptionSmall';
import TitleLarge from 'components/ui/atoms/text/TitleLarge';

import { FormSectionProps } from './types';

const Container = styled.div(
  ({ theme: { spacing } }) => css`
    margin-top: ${spacing(6)};
  `,
);

const FormSection = ({
  title,
  columns,
  preface,
  formFields,
  validatedFields,
  error,
  touched,
  handleChange,
  isFormValuesChanged,
  Footer,
  isFormSubmitted = false,
}: FormSectionProps) => {
  return (
    <Container>
      {title && <TitleLarge marginBottom={Margin.Large}>{title}</TitleLarge>}
      {preface && <CaptionSmall marginBottom={Margin.Large}>{preface}</CaptionSmall>}
      <GridContainer>
        {formFields.map(({ id, name, columns: elementColumns, ...rest }) => (
          <GridFormElement
            id={id || name}
            key={name}
            name={name}
            value={validatedFields[name]}
            error={(touched?.[name] || isFormSubmitted) && !!error?.[name]}
            errorText={(touched?.[name] || isFormSubmitted) && error?.[name]}
            onChange={handleChange}
            columns={elementColumns || columns}
            {...rest}
          />
        ))}
      </GridContainer>
      {Footer && <Footer isFormValuesChanged={isFormValuesChanged} validatedFields={validatedFields} />}
    </Container>
  );
};

export default FormSection;
