import styled from '@emotion/styled';
import { css } from '@emotion/react';

const GAP = 3;

export const Wrapper = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing(GAP)};
  `,
);

export const FormBlock = styled.div(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: ${`calc(100% / 2 - ${spacing(GAP)});`};

    ${down(breakpoints.lg)} {
      width: 100%;
    }
  `,
);
