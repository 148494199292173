import { ThemeProvider as StyledThemeProvider, Global } from '@emotion/react';
import { ThemeProvider as MaterialThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import DefaultTheme from 'styles/themes/defaultTheme';
import globalStyles from 'styles/globalStyles';

import { THEMES } from './themes';
import { ThemeProviderProps } from './types';

const ThemeProvider = ({ children, themeType }: ThemeProviderProps) => {
  const theme = themeType ? THEMES[themeType] : DefaultTheme;

  return (
    <StyledThemeProvider theme={theme}>
      <MaterialThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
      </MaterialThemeProvider>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
