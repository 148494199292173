import Input from '@mui/material/OutlinedInput';
import { Theme, styled } from '@mui/material/styles';

const styles = ({ theme }: { theme: Theme }) => ({
  '& .MuiInputBase-input': {
    height: '40px',
    boxSizing: 'border-box',
    ...theme.typography.input,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.inputForMobile,
    },
    background: theme.colors.white,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border,
  },

  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border,
  },

  '&:hover:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border,
  },

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },

    '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },

  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5, 2),
  },

  '&.Mui-disabled': {
    background: 'transparent',
    color: theme.palette.text.secondary,
  },
});

const StyledInput = styled(Input)(styles);

export default StyledInput;
