import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { APPLY } from 'config/routes';
import { THANK_YOU_PAGE_URL } from 'config/constants/queryParams';
import useReferralId from 'hooks/useReferralId';
import { getApplyReferralIdFromOrigin } from 'utils/getApplyReferralIdFromOrigin';
import openThirdPartyLink from 'utils/openThirdPartyLink';

import Loader from 'components/ui/molecules/Loader';
import RedirectingMessage from 'components/ui/atoms/messages/RedirectingMessage';

import { FormSubmitButton } from '../../styled';

const DataSentInfo = () => {
  const navigate = useNavigate();
  const userReferralId = useReferralId(getApplyReferralIdFromOrigin);

  const [searchParams] = useSearchParams();
  const thankYouPage = searchParams.get(THANK_YOU_PAGE_URL);

  const [showRedirecting, setShowRedirecting] = useState(false);

  const redirectToLanding = () => {
    // TODO: add all queries from iframe (thankYouPage...)
    const pathname = userReferralId ? `${APPLY}/${userReferralId}` : APPLY;
    navigate({ pathname, search: `?${createSearchParams(searchParams)}` });
  };

  useEffect(() => {
    if (thankYouPage) {
      const { openLink, isSameDomainLink } = openThirdPartyLink(thankYouPage);
      openLink();
      if (isSameDomainLink) {
        setShowRedirecting(true);
      }
    }
  }, [thankYouPage]);

  if (showRedirecting)
    return (
      <Loader>
        <RedirectingMessage />
      </Loader>
    );

  return (
    <FormSubmitButton onClick={redirectToLanding} variant="contained" disableElevation>
      Back to Homepage
    </FormSubmitButton>
  );
};

export default DataSentInfo;
