import { withTheme } from '@emotion/react';

import Icon, { Icons } from 'components/ui/atoms/Icon';

import getSchema from './getSchema';
import type { AlertProps } from './types';
import { Wrapper, PendingAnimation, Text, Container, IconWrapper, StyledIconButton } from './styled';

const Alert = ({
  theme,
  children,
  type,
  iconType,
  action,
  pending = false,
  withIcon = true,
  noWrap = false,
}: AlertProps) => {
  const schema = getSchema({ theme, type });

  return (
    <Wrapper>
      {pending && <PendingAnimation schema={schema} data-testid="alert-pending-animation" />}
      <Container schema={schema} pending={pending}>
        {withIcon && (
          <IconWrapper>
            <Icon type={iconType || schema.icon} stroke={schema.iconStroke} />
          </IconWrapper>
        )}
        <Text noWrap={noWrap} schema={schema}>
          {children}
        </Text>
        {action && (
          <StyledIconButton
            iconType={Icons.X}
            stroke={schema.iconStroke}
            onClick={action}
            data-testid="alert-action-button"
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default withTheme(Alert);
