import { useSearchParams } from 'react-router-dom';

import useUpdateIntakeFormBusinessDetailsV2Mutation from 'api/mutations/intakeForm/useUpdateIntakeFormBusinessDetailsV2Mutation';

import useNotifier from 'hooks/useNotifier';
import { STEP, UUID } from 'config/constants/queryParams';
import useAddingQueryParams from 'hooks/query/useAddingQueryParams';

import { INTAKE_FORM_STEPS } from '../../config';

const useUpdateIntakeFormBusinessDetails = () => {
  const [searchParams] = useSearchParams();
  const { addQueryParams } = useAddingQueryParams();

  const { setError } = useNotifier();

  const [updateIntakeFormBusinessDetailsMutation, { loading }] = useUpdateIntakeFormBusinessDetailsV2Mutation({
    onCompleted: () => {
      addQueryParams({
        [STEP]: INTAKE_FORM_STEPS.Step3,
      });
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  // TODO: use correct fields for this mutation
  const updateIntakeFormBusinessDetails = async ({ fields }: { fields: Record<string, any> }) => {
    const uuid = searchParams.get(UUID);
    const step = searchParams.get(STEP);
    const variables = {
      ...fields,
      uuid,
      step,
    };
    await updateIntakeFormBusinessDetailsMutation({
      variables,
    });
  };
  return { updateIntakeFormBusinessDetails, loading };
};

export default useUpdateIntakeFormBusinessDetails;
