import FormWithValidation, { SubmitHandler } from 'components/ui/molecules/FormWithValidation';
import type { FormFooterProps } from 'components/ui/molecules/FormWithValidation/types';
import { FormSectionMediator } from 'components/pages/ERC/components/form/formSection';
import FormFooter, { Alignment } from 'components/shared/organisms/form/FormFooter';
import ConfirmationText from 'components/shared/organisms/form/ConfirmationText';

import useCreateIntakeForm from './hooks/useCreateIntakeForm';
import useIntakeFormValues from './hooks/useIntakeFormValues';
import { fields as formFields, requiredValidationFields } from './config';
import { FormWrapper, FormFooterWrapper } from './styled';

const ButtonSubmit = ({ submitHandler, disabled }: FormFooterProps) => (
  <FormFooterWrapper>
    <FormFooter
      disabled={disabled}
      submitHandler={submitHandler}
      buttonConfirmationText={<ConfirmationText />}
      buttonText="See How Much You Qualify For"
      footerAlignment={Alignment.Stretch}
    />
  </FormFooterWrapper>
);

const formConfig = {
  id: 'createLead',
  columns: 1,
  title: 'Review Business Credit Line',
  withHiddenFields: false,
  formFields,
};

const Form = (): JSX.Element => {
  const { createIntakeForm, loading } = useCreateIntakeForm();

  const handleSubmit: SubmitHandler = async (fields) => {
    await createIntakeForm({ fields });
  };

  const { data: formValues, loading: formValuesLoading } = useIntakeFormValues();

  return (
    <FormWrapper>
      <FormWithValidation
        id="apply-landing-form"
        defaultValues={formValues}
        requiredValidationFields={requiredValidationFields}
        submitHandler={handleSubmit}
        FormFooter={ButtonSubmit}
        loading={loading || formValuesLoading}
      >
        <FormSectionMediator key={formConfig.id} {...formConfig} />
      </FormWithValidation>
    </FormWrapper>
  );
};

export default Form;
