import { convertDollarToCent } from 'lib/money';

import type { EventValue } from '../types';

const getExportedValue = (value: EventValue): EventValue => {
  if (value === null) return value;

  const exportedValue = convertDollarToCent(value);

  return Number.isNaN(exportedValue) ? null : exportedValue;
};

export default getExportedValue;
