import {
  YUP_NULLABLE_REQUIRED,
  YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  YUP_REQUIRED_PHONE_NUMBER,
} from 'config/constants/yup';

import EmailInput from 'components/ui/atoms/forms/EmailInput';
import PhoneInput from 'components/ui/atoms/forms/PhoneInput';
import StringInput from 'components/ui/atoms/forms/StringInput';

enum ContactInformationFormFields {
  MerchantFirstName = 'merchantFirstName',
  MerchantLastName = 'merchantLastName',
  MerchantEmail = 'merchantEmail',
  MerchantCellPhone = 'merchantCellPhone',
  BusinessName = 'businessName',
  Documents = 'documents',
}

export const getContactInformationFields = (isMobile: boolean) => [
  {
    id: 'first-name',
    name: ContactInformationFormFields.MerchantFirstName,
    label: 'First Name',
    placeholder: 'Enter first name',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 2,
  },
  {
    id: 'last-name',
    name: ContactInformationFormFields.MerchantLastName,
    label: 'Last Name',
    placeholder: 'Enter last name',
    control: <StringInput />,
    required: true,
    columns: isMobile ? 1 : 2,
  },
  {
    id: 'business-name',
    name: ContactInformationFormFields.BusinessName,
    label: 'Legal Company Name',
    control: <StringInput />,
    required: true,
  },
  {
    id: 'email',
    name: ContactInformationFormFields.MerchantEmail,
    label: 'Email',
    control: <EmailInput />,
    required: true,
  },
  {
    id: 'phone-number',
    name: ContactInformationFormFields.MerchantCellPhone,
    label: 'Phone Number',
    control: <PhoneInput />,
    required: true,
  },
];

export const DEFAULT_VALUES = {
  [ContactInformationFormFields.MerchantFirstName]: null,
  [ContactInformationFormFields.MerchantLastName]: null,
  [ContactInformationFormFields.BusinessName]: null,
  [ContactInformationFormFields.MerchantEmail]: null,
  [ContactInformationFormFields.MerchantCellPhone]: null,
  [ContactInformationFormFields.Documents]: null,
};

export const requiredValidationFields = {
  [ContactInformationFormFields.MerchantFirstName]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.MerchantLastName]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.BusinessName]: YUP_NULLABLE_REQUIRED,
  [ContactInformationFormFields.MerchantEmail]: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  [ContactInformationFormFields.MerchantCellPhone]: YUP_REQUIRED_PHONE_NUMBER,
};
