import { useState, useMemo, useRef } from 'react';
import ErrorDecorator from 'decorators/ErrorDecorator';
import TYPES from 'config/types/notifierTypes';

import NotifierContext from './NotifierContext';
import Notifiers from './Notifiers';

const NotifierProvider = ({ children }) => {
  const { current: notifiers } = useRef(new Notifiers());
  const [notifierList, setNotifierList] = useState(notifiers.newList);

  const setError = (error = {}) => {
    const messages = new ErrorDecorator(error).getMessages();

    messages.forEach((message) =>
      notifiers.set({
        message,
        type: TYPES.error,
        actionTimeOut: undefined,
      }),
    );

    setNotifierList(notifiers.newList);
  };

  const setSuccess = (message = {}) => {
    notifiers.set({
      message,
      type: TYPES.success,
      actionTimeOut: 5000,
    });

    setNotifierList(notifiers.newList);
  };

  const clearMessage = (id) => {
    if (notifiers.remove(id)) {
      setNotifierList(notifiers.newList);
    }
  };

  const context = useMemo(
    () => ({
      notifierList,
      setError,
      setSuccess,
      clearMessage,
    }),
    [notifierList],
  );

  return <NotifierContext.Provider value={context}>{children}</NotifierContext.Provider>;
};

export default NotifierProvider;
