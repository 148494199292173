import { useSearchParams } from 'react-router-dom';

import useNotifier from 'hooks/useNotifier';
import useReferralId from 'hooks/useReferralId';
import { getShortApplicationReferralIdFromOrigin } from 'utils/getShortApplicationFormReferralIdFromOrigin';
import { ORIGIN } from 'config/constants/queryParams';

import useCreateOneStepFormMutation from 'api/mutations/oneStepForm/useCreateOneStepFormMutation';
import { DocumentGroups } from 'graphql/types/enums/documentGroups';
import { ReactStateSetter } from 'types/stateSetter';
import { UploadedFile } from 'components/ui/molecules/File/types';

export type OnCompleted = (() => void) | undefined;
export type UseCreateOneStepFormArgs = {
  setIsSubmitted: ReactStateSetter<boolean>;
  onCompleted: OnCompleted;
};

const useCreateOneStepForm = ({ setIsSubmitted, onCompleted }: UseCreateOneStepFormArgs) => {
  const { setError, setSuccess } = useNotifier();

  const [searchParams] = useSearchParams();
  const referralId = useReferralId(getShortApplicationReferralIdFromOrigin);
  const origin = searchParams.get(ORIGIN);

  const [createOneStepForm, { loading }] = useCreateOneStepFormMutation({
    onCompleted: () => {
      setSuccess('Short application form was submitted successfully!');
      setIsSubmitted(true);
      onCompleted?.();
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  const createForm = async ({
    fields,
    uploadedBankStatements,
  }: {
    fields: any;
    uploadedBankStatements: UploadedFile | Record<string, unknown>;
  }) => {
    const documents = Object.values(uploadedBankStatements).map((file) => ({
      file,
      group: DocumentGroups.BankStatement,
      name: 'Bank Statement',
    }));

    await createOneStepForm({
      variables: {
        ...fields,
        userReferralId: referralId,
        applicationFormSource: origin || window.location.href,
        documentsAttributes: documents,
      },
    });
  };

  return { createForm, loading };
};

export default useCreateOneStepForm;
