import FormatInput from 'components/ui/atoms/forms/FormatInput';

import { SSN_FORMAT, SSN_PLACEHOLDER } from './config';
import type SSNInputProps from './types';

const SSNInput = (props: SSNInputProps): JSX.Element => (
  <FormatInput format={SSN_FORMAT} placeholder={SSN_PLACEHOLDER} {...props} />
);

export default SSNInput;
