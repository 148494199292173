import { useSearchParams } from 'react-router-dom';

import useUpdateIntakeFormBasicInfoV2Mutation from 'api/mutations/intakeForm/useUpdateIntakeFormBasicInfoV2Mutation';

import useNotifier from 'hooks/useNotifier';
import { STEP, UUID } from 'config/constants/queryParams';
import useAddingQueryParams from 'hooks/query/useAddingQueryParams';

import { INTAKE_FORM_STEPS } from '../../config';
import { BasicInformation } from './config';

const useUpdateIntakeFormBasicInfo = () => {
  const { addQueryParams } = useAddingQueryParams();

  const [searchParams] = useSearchParams();

  const { setError } = useNotifier();

  const [updateIntakeFormBasicInfoMutation, { loading }] = useUpdateIntakeFormBasicInfoV2Mutation({
    onCompleted: () => {
      addQueryParams({ [STEP]: INTAKE_FORM_STEPS.Step2 });
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  // TODO: use correct fields for this mutation
  const updateIntakeFormBasicInfo = async ({ fields }: { fields: Record<string, any> }) => {
    const uuid = searchParams.get(UUID);
    const step = searchParams.get(STEP);

    const variables = {
      ...fields,
      [BasicInformation.OpenedBusinessLoan]: fields[BasicInformation.OpenedBusinessLoan],
      uuid,
      step,
    };

    await updateIntakeFormBasicInfoMutation({
      variables,
    });
  };

  return { updateIntakeFormBasicInfo, loading };
};

export default useUpdateIntakeFormBasicInfo;
