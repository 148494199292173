import { useSearchParams } from 'react-router-dom';

import useUpdateIntakeFormLastStepV2Mutation from 'api/mutations/intakeForm/useUpdateIntakeFormLastStepV2Mutation';

import useNotifier from 'hooks/useNotifier';
import { STEP, UUID } from 'config/constants/queryParams';
import useAddingQueryParams from 'hooks/query/useAddingQueryParams';

import { INTAKE_FORM_STEPS } from '../../config';
import useCreateIntakeFormDocument from './useCreateIntakeFormDocument';

const useSubmitForm = () => {
  const [searchParams] = useSearchParams();
  const { addQueryParams } = useAddingQueryParams();

  const { setError } = useNotifier();

  const { loading: documentsLoading, createIntakeFormDocument, isError } = useCreateIntakeFormDocument();

  const [updateIntakeFormLastStep, { loading }] = useUpdateIntakeFormLastStepV2Mutation({
    onCompleted: () => {
      if (isError) return;
      addQueryParams({ [STEP]: INTAKE_FORM_STEPS.Step4 });
    },
    onError: (error) => {
      setError(`Form error: ${error}`);
    },
  });

  const updateIntakeForm = async () => {
    await updateIntakeFormLastStep({
      variables: {
        uuid: searchParams.get(UUID),
        step: INTAKE_FORM_STEPS.Step4,
      },
    });
  };

  return { updateIntakeForm, createIntakeFormDocument, loading: documentsLoading || loading };
};

export default useSubmitForm;
