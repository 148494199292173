import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const getScrollableStyles = ({ spacing, palette }) => css`
  &::-webkit-scrollbar {
    width: ${spacing(0.5)};
    height: ${spacing(0.5)};
    border-radius: ${spacing(0.75)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${spacing(0.75)};
    background: ${palette.scrollThumb};
  }
`;
export const getScrollableMaterialStyles = ({ spacing, palette }) => ({
  '&::-webkit-scrollbar': {
    width: spacing(0.5),
    height: spacing(0.5),
    borderRadius: spacing(0.75),
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: spacing(0.75),
    background: `${palette.scrollThumb}`,
  },
});

const Scrollable = styled.div(
  ({ theme, maxHeight, height, vertical = true, horizontal = false }) => css`
    width: 100%;
    max-height: ${maxHeight || '100%'};
    height: ${height || 'auto'};
    overflow-y: ${vertical ? 'auto' : 'hidden'};
    overflow-x: ${horizontal ? 'auto' : 'hidden'};

    ${getScrollableStyles(theme)}
  `,
);

export default Scrollable;
