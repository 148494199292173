import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { CustomTestType } from 'types/testType';

import { TextType } from '../types';

type CaptionMediumProps = CustomTestType & TextType;

const StyledCaption = styled.span<Pick<CaptionMediumProps, 'disabled' | 'textCase'>>(
  ({ theme: { palette, typography }, disabled }) =>
    css`
      ${typography.t1};
      color: ${disabled ? palette.text.disabled : palette.text.secondary};
    `,
);

const CaptionMedium = ({ children, disabled, textCase, dataTestId }: CaptionMediumProps) => (
  <StyledCaption disabled={disabled} textCase={textCase} data-testid={dataTestId}>
    {children}
  </StyledCaption>
);

export default CaptionMedium;
