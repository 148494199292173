export const convertBlobToFile = (blob, file) => {
  if (!blob) return file;

  /* eslint-disable no-param-reassign */
  blob.name = file.name;
  blob.path = file.path;
  blob.lastModified = file.lastModified;
  blob.lastModifiedDate = file.lastModifiedDate;
  /* eslint-enable no-param-reassign */

  return blob;
};

export const getFileToUpload = (file) => {
  let blob = null;
  const splitedFileName = file.name.split('.');
  const extension = splitedFileName[splitedFileName.length - 1];
  const isCSV = file.type !== 'text/csv' && extension === 'csv';
  const isConvertedToBlob = isCSV;

  if (isCSV) {
    // eslint-disable-next-line no-undef
    blob = new Blob([file], { type: 'text/csv' });
  }

  return isConvertedToBlob ? convertBlobToFile(blob, file) : file;
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // eslint-disable-next-line no-undef
  return new File([u8arr], filename, { type: mime });
};

export const getFileVars = (uploadedFile) => {
  const { metadata, id, storage } = uploadedFile;
  const { filename, mimeType, size } = metadata;
  return {
    filename,
    mimeType,
    size,
    id,
    storage,
  };
};
