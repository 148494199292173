import BaseMaskInput from './BaseMaskInput';
import { toString, getCustomEvent } from './helpers';
import { EventHandler, MaskStringInputProps } from './types';

const MaskStringInput = ({ onChange, onBlur, nullable, ...props }: MaskStringInputProps) => {
  const handleChange: EventHandler = (event) => {
    onChange?.(getCustomEvent(event, toString(event.target.value), nullable));
  };

  const handleBlur: EventHandler = (event) => {
    onBlur?.(getCustomEvent(event, toString(event.target.value), nullable));
  };

  return <BaseMaskInput onChange={handleChange} onBlur={handleBlur} {...props} />;
};

export default MaskStringInput;
