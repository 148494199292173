import { ChangeEvent, FocusEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';

import type { Event, KeyboardEvent } from '../types';

const transformToNullable = (value: string): string | null => (value === '' ? null : value);

export const getCustomEvent = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  isNoneNullable?: boolean,
): Event => ({
  preventDefault: () => event.preventDefault(),
  target: {
    name: event.target.name,
    value: isNoneNullable ? event.target.value : transformToNullable(event.target.value),
  },
});

export const getKeyboardEvent = (event: ReactKeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): KeyboardEvent => {
  const target = event.target as HTMLTextAreaElement | HTMLInputElement;

  return {
    preventDefault: () => event.preventDefault(),
    key: event.key,
    target: {
      name: target.name,
      value: target.value,
    },
  };
};

export default getCustomEvent;
