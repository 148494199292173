import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledStepper = styled(Stepper)(
  ({
    theme: {
      spacing,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: 100%;
    overflow-x: auto;
    padding: ${spacing(3)};

    ${down(breakpoints.md)} {
      && {
        padding: ${spacing(3, 2)};
      }
    }
  `,
);

export const StyledStepLabel = styled(StepLabel)`
  white-space: nowrap;
`;
