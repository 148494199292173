import { ChangeEvent } from 'react';

import { DEV } from 'config/vars';

import { FormSectionProps } from 'components/pages/ERC/components/form/types/FormSectionProps';
import FormSection from 'components/shared/organisms/form/FormSection';

import FormSectionWithHiddenFields from './FormSectionWithHiddenFields';

type FormSectionMediatorProps = Omit<FormSectionProps, 'handleChange'> & {
  withHiddenFields: boolean;
  handleChange?: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
};

const FormSectionMediator = ({ withHiddenFields, handleChange, ...props }: FormSectionMediatorProps) => {
  if (!handleChange) {
    if (DEV) throw new Error('handleChange prop was not provided');
    return null;
  }
  return withHiddenFields ? (
    <FormSectionWithHiddenFields handleChange={handleChange} {...props} />
  ) : (
    <FormSection handleChange={handleChange} {...props} />
  );
};

export default FormSectionMediator;
