import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const CreateCreditCardProcessingFormMutation = loader(
  '../../../graphql/mutations/ccpForm/createCreditCardProcessingForm.graphql',
);

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useCreateCcpFormMutation = ({ onCompleted, onError }: Props) => {
  const [createCcpForm, mutationResult] = useMutation(CreateCreditCardProcessingFormMutation, { onCompleted, onError });

  return [createCcpForm, mutationResult] as MutationTuple<any, any>;
};

export default useCreateCcpFormMutation;
