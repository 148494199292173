import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const FormWrapper = styled.div(
  ({
    theme: {
      spacing,
      palette,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: 530px;
    padding: ${spacing(0.5, 4.75, 5.625)};
    border-radius: 4px;
    background-color: ${palette.primary.contrastText};
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.16), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);

    ${down(breakpoints.md)} {
      width: 100%;
      border-radius: 0;

      h2 {
        text-align: center;
      }
    }
  `,
);

export const FormFooterWrapper = styled.div`
  text-align: center;
`;
