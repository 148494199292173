import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(
  ({
    theme: {
      typography,
      palette: { buttons },
      shape,
      spacing,
    },
  }) => ({
    ...typography.button,
    textTransform: 'none',
    color: buttons.text.primary.text,
    borderRadius: shape.borderRadius,
    padding: spacing(1, 3),
    lineHeight: '20px',

    '&.MuiButton-sizeSmall': {
      padding: spacing(1, 2),
    },

    '&.MuiButton-sizeLarge': {
      padding: spacing(1.5, 7),
    },

    '&.MuiButton-text.MuiButton-colorSecondary': {
      borderColor: 'transparent',
      color: buttons.text.secondary.text,

      '& .MuiButton-startIcon': {
        '& > svg': {
          stroke: buttons.text.secondary.text,
        },
      },

      '&.Mui-disabled': {
        color: buttons.text.disabled.text,

        '& .MuiButton-startIcon': {
          '& > svg': {
            color: buttons.text.disabled.text,
          },
        },
      },
    },

    '&.MuiButton-text.MuiButton-colorPrimary': {
      borderColor: 'transparent',
      color: buttons.text.primary.text,

      '& .MuiButton-startIcon': {
        '& > svg': {
          stroke: buttons.text.primary.text,
        },
      },

      '&.Mui-disabled': {
        color: buttons.text.disabled.text,

        '& .MuiButton-startIcon': {
          '& > svg': {
            color: buttons.text.disabled.text,
          },
        },
      },
    },

    '&.MuiButton-contained': {
      background: buttons.contained.default.background,

      '&:hover': {
        background: buttons.contained.default.background,
      },
    },

    '&.MuiButton-contained.MuiButton-colorPrimary': {
      color: buttons.contained.primary.text,
      background: buttons.contained.primary.background,

      '&:hover': {
        background: buttons.contained.primary.hoverBackground,
      },

      '& .MuiButton-startIcon': {
        '& > svg': {
          stroke: buttons.contained.primary.text,
        },
      },

      '&.Mui-disabled': {
        color: buttons.contained.disabled.text,
        background: buttons.contained.disabled.background,
        border: buttons.contained.disabled.border,

        '& .MuiButton-startIcon': {
          '& > svg': {
            stroke: buttons.contained.disabled.text,
          },
        },
      },
    },

    '&.MuiButton-contained.MuiButton-colorSecondary': {
      color: buttons.contained.secondary.text,
      background: buttons.contained.secondary.background,

      '&:hover': {
        background: buttons.contained.secondary.hoverBackground,
      },

      '& .MuiButton-startIcon': {
        '& > svg': {
          stroke: buttons.contained.secondary.text,
        },
      },

      '&.Mui-disabled': {
        color: buttons.contained.disabled.text,
        background: buttons.contained.disabled.background,
        border: buttons.contained.disabled.border,

        '& .MuiButton-startIcon': {
          '& > svg': {
            stroke: buttons.contained.disabled.text,
          },
        },
      },
    },

    '&.MuiButton-contained.MuiButton-colorInherit': {
      background: buttons.contained.inherit.background,
      color: buttons.contained.inherit.text,

      '&:hover': {
        background: buttons.contained.inherit.hoverBackground,
      },
    },

    '&.MuiButton-outlined.MuiButton-colorPrimary': {
      color: buttons.outlined.primary.text,

      '& .MuiButton-startIcon': {
        '& > svg': {
          stroke: buttons.outlined.primary.text,
        },
      },

      '&.Mui-disabled': {
        color: buttons.outlined.disabled.text,

        '&::placeholder': {
          color: buttons.outlined.disabled.text,
        },

        '& .MuiButton-startIcon': {
          '& > svg': {
            stroke: buttons.outlined.disabled.text,
          },
        },
      },
    },

    '&.MuiButton-outlined.MuiButton-colorSecondary': {
      color: buttons.outlined.secondary.text,
      borderColor: buttons.outlined.secondary.border,

      '& .MuiButton-startIcon': {
        '& > svg': {
          stroke: buttons.outlined.secondary.text,
        },
      },

      '&:hover': {
        borderColor: buttons.outlined.secondary.border,
        background: buttons.outlined.secondary.hoverBackground,
      },

      '&.Mui-disabled': {
        color: buttons.outlined.disabled.text,

        '&::placeholder': {
          color: buttons.outlined.disabled.text,
        },

        '& .MuiButton-startIcon': {
          '& > svg': {
            stroke: buttons.outlined.disabled.text,
          },
        },
      },
    },
  }),
);
