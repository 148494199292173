import { FileUploader } from 'api/__generated__/types';
import useNotifier from 'hooks/useNotifier';

import useCreateResubscribeFormMutation from 'api/mutations/resubscribe';

import { UseCreateResubscribeFormProps, CreateFormProps } from '../types';

const useCreateResubscribeForm = ({
  setIsSuccessSubmittedForm,
  onCompleted,
  uploadSignature,
  setApiErrors,
  setIsFormSubmitted,
}: UseCreateResubscribeFormProps) => {
  const { setSuccess } = useNotifier();

  const [createResubscribeForm, { loading }] = useCreateResubscribeFormMutation({
    onCompleted: () => {
      setSuccess('Resubscribe form was submitted successfully!');
      setIsSuccessSubmittedForm(true);
      onCompleted?.();
    },
    // @ts-expect-error remove after add custom types for ApolloError
    onError: ({ fieldErrors }) => {
      setApiErrors(fieldErrors);
    },
  });

  const createForm = async ({ fields }: CreateFormProps) => {
    setIsFormSubmitted(true);
    const signatureVariables = await uploadSignature();

    const { mobilePhone, businessEmail, ...otherFields } = fields;
    const variables = {
      ...otherFields,
      ...(mobilePhone ? { mobilePhone } : {}),
      ...(businessEmail ? { businessEmail } : {}),
      ...(signatureVariables as FileUploader),
    };

    await createResubscribeForm({
      // @ts-expect-error remove after FE and BE type comparisons
      variables,
    });
  };

  return { createForm, loading };
};

export default useCreateResubscribeForm;
