import Box from '@mui/material/Box';

import noop from 'lib/utils/noop';
import useFileUploader from 'hooks/useFileUploader';

import Dropzone from 'components/ui/molecules/Dropzone';
import File from 'components/ui/molecules/File';

import type { FileUploaderProps } from './types';

const FileUploader = ({
  dropZoneText,
  fileInputProps,
  sizeLimitMb,
  multiple = false,
  onFilesChange = noop,
  setUploaded,
  setLoading,
}: FileUploaderProps) => {
  const { localFiles, upload, showDropZone, setUploads, uploadFiles, handleCancelFile } = useFileUploader({
    setUploaded,
    sizeLimitMb,
    multiple,
    onFilesChange,
  });

  return (
    <>
      {showDropZone && (
        <Dropzone
          onFilesChanged={uploadFiles}
          multiple={multiple}
          text={dropZoneText}
          fileInputProps={fileInputProps}
        />
      )}
      {Object.entries(localFiles).map(
        ([key, value]) =>
          value && (
            <Box my={1} key={key}>
              <File
                /* @ts-expect-error add expected type for value */
                localFile={value.file}
                onCancel={() => handleCancelFile(key)}
                setUploads={setUploads}
                /* @ts-expect-error check upload type */
                upload={upload}
                setLoading={setLoading}
              />
            </Box>
          ),
      )}
    </>
  );
};

export default FileUploader;
