import { loader } from 'graphql.macro';
import { useMutation, MutationTuple } from '@apollo/client';

const UpdateIntakeFormBusinessDetailsV2Mutation = loader(
  '../../../graphql/mutations/intakeForm/updateIntakeFormBusinessDetailsV2.graphql',
);

type Props = {
  onCompleted: (data: any) => void;
  onError: (error: any) => void;
};

const useUpdateIntakeFormBusinessDetailsV2Mutation = ({ onCompleted, onError }: Props) => {
  const [updateIntakeFormMutation, mutationResult] = useMutation(UpdateIntakeFormBusinessDetailsV2Mutation, {
    onCompleted,
    onError,
  });

  return [updateIntakeFormMutation, mutationResult] as MutationTuple<any, any>;
};

export default useUpdateIntakeFormBusinessDetailsV2Mutation;
