import { useEffect, useState } from 'react';

import Breakpoints from 'styles/breakpoints';

const useCheckMobileScreen = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenWidth < Breakpoints.md;
};

export default useCheckMobileScreen;
