import { ChangeEvent, memo } from 'react';
import { useRadioGroup } from '@mui/material/RadioGroup';

import { RadioButtonKinds, RadioGroupProps, RadioItemProps } from './types';
import getCustomEvent from './helpers/getCustomEvent';
import {
  StyledRadioButtonLabel,
  StyledRadio,
  StyledRadioGroup,
  StyledRadioCardLabel,
  StyledRadioCardGroup,
} from './styled';

const RadioItem = memo(({ value, label, disabled, name, type }: RadioItemProps) => {
  const radioGroup = useRadioGroup();
  const StyledLabel = type === RadioButtonKinds.Usual ? StyledRadioButtonLabel : StyledRadioCardLabel;

  return (
    <StyledLabel
      labelPlacement="start"
      value={value ?? ''}
      label={label}
      disabled={disabled}
      name={name}
      checked={radioGroup?.value === value}
      control={<StyledRadio data-testid="radio-control" />}
    />
  );
});

// error prop is get from FromControl, but this argument doesn't exist in RadioGroup
// @ts-expect-error ignore no used value
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RadioGroup = ({ radioItems, onChange, error, type = RadioButtonKinds.Usual, ...other }: RadioGroupProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(getCustomEvent(event));
  };

  const StyledGroup = type === RadioButtonKinds.Usual ? StyledRadioGroup : StyledRadioCardGroup;

  return (
    <StyledGroup {...other} onChange={handleChange}>
      {radioItems?.map(({ value, label, disabled, name }, index) => {
        return <RadioItem key={index} value={value} label={label} disabled={disabled} name={name} type={type} />;
      })}
    </StyledGroup>
  );
};

export default RadioGroup;
