import { DEV } from 'config/vars';
import { ApolloClientError } from './ApolloClientError';

// Called if network connection is lost or socket is hung up
export const parseDefaultNetworkError = (networkError) =>
  networkError ? `Something went wrong (${networkError.message || 'network error'})` : '';

// common(array[string]) for user
// detail(string) for developer
export const parseDefaultDevError = (common, detail) => {
  const commonText = common.join(', ');

  return DEV ? `${detail} (${commonText})` : commonText;
};

export default class ApolloErrorDecorator {
  errors = [];

  networkError = null;

  devErrors = [];

  fieldErrors = {};

  generalErrors = [];

  constructor(
    { networkError, graphQLErrors } = {},
    parseNetworkError = parseDefaultNetworkError,
    parseDevError = parseDefaultDevError,
  ) {
    if (!networkError && !graphQLErrors) return;

    this.parseDefaultNetworkError = parseNetworkError;
    this.parseDefaultDevError = parseDevError;

    this.setNetworkError(networkError);
    this.setGraphQLErrors(graphQLErrors);
  }

  // common(array[string]) for user
  // detail(string) for developer
  setDevErrors = (common, detail) => this.devErrors.push({ common, detail });

  setNetworkError = (networkError) => {
    this.networkError = networkError ?? null;
  };

  // fields(Object{[nameField]: array[string]})
  setFieldError = (fields) => Object.assign(this.fieldErrors, fields);

  setGeneralErrors = (message) => this.generalErrors.push(message);

  setGraphQLErrors = (errors) => {
    errors?.forEach(({ extensions, message }) => {
      // Field error
      if (extensions?.detail) {
        this.setFieldError(extensions.detail);
      }

      // Dev error
      if (extensions?.problems) {
        this.setDevErrors(
          extensions.problems.map(({ explanation }) => explanation),
          message,
        );
        return;
      }

      if (extensions?.code) {
        this.errors.push({
          code: extensions?.code,
          message,
        });
      }

      this.setGeneralErrors(message);
    });
  };

  getErrors = () => new ApolloClientError(this.fieldErrors, this.generalErrors, this.errors);

  showNetworkError = (show, parseError = this.parseDefaultNetworkError) => {
    if (!this.networkError) return;
    if (typeof show === 'function') {
      show(parseError(this.networkError));
    }
  };

  showDevError = (show, parseError = this.parseDefaultDevError) => {
    if (!this.devErrors.length) return;

    const errors = [];

    this.devErrors.forEach(({ common, detail }) => {
      errors.push(parseError(common, detail));
    });

    if (typeof show === 'function') {
      show(errors.join('; '));
    }
  };

  showGeneralError = (show) => {
    if (!this.generalErrors.length) return;

    if (typeof show === 'function') {
      show(this.generalErrors.join('; '));
    }
  };
}
