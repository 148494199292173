class Notifier {
  constructor({ message = '', type = '', actionTimeOut }) {
    this.id = Date.now();
    this.message = message;
    this.type = type;
    this.actionTimeOut = actionTimeOut;
  }
}

export default Notifier;
