import { useSearchParams } from 'react-router-dom';

import ThemeProvider, { ThemeTypes } from 'contexts/ThemeContext';
import { NotifierProvider } from 'contexts/NotifierContext';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { BRANDED_PAGE } from 'config/constants/queryParams';
import { isFalse } from 'lib/utils/boolean';

import Notifier from 'components/ui/atoms/Notifier';
import NoSpacingTemplate from 'components/templates/NoSpacingTemplate';

import Header from './components/Header';
import Title from './components/Title';
import Form from './components/Form';
import FundingSteps from './components/FundingsSteps';
import { FormContainer, Main } from './styled';

const ApplyLanding = () => {
  const isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const brandedPage = !isFalse(searchParams.get(BRANDED_PAGE));

  return (
    <ThemeProvider themeType={ThemeTypes.Green}>
      <NotifierProvider>
        <NoSpacingTemplate>
          <Header brandedPage={brandedPage} />
          <Main>
            <FormContainer>
              <Title />
              {!isMobile && <Form />}
            </FormContainer>
          </Main>
          {isMobile && <Form />}
          <FundingSteps />
          <Notifier />
        </NoSpacingTemplate>
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default ApplyLanding;
