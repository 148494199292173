import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { transparentize } from 'polished';

const Wrapper = styled.div<any>(
  ({ customStyles, theme, theme: { palette } }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${transparentize(0.4, palette.common.white)};
    opacity: 0.7;
    z-index: 100;
    ${customStyles && customStyles(theme)}
  `,
);

type LoaderProps = {
  children?: ReactNode;
  customStyles?: CSSStyleSheet;
};

const Loader = ({ children, customStyles }: LoaderProps) =>
  children ? (
    <Wrapper customStyles={customStyles} data-testid="loader">
      {children}
    </Wrapper>
  ) : (
    <Wrapper customStyles={customStyles} data-testid="loader" />
  );

export default Loader;
