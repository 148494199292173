import FormContainer from './components/FormContainer';
import usePublicFinancingRequest from './usePublicFinancingRequest';

const CalculatorContainer = ({ uuid }: { uuid: string }) => {
  const { data, handleUpdate, termUnitText } = usePublicFinancingRequest(uuid);

  return <FormContainer data={data} termUnitText={termUnitText} handleUpdate={handleUpdate} />;
};

export default CalculatorContainer;
