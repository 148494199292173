import { PaletteMode } from '@mui/material';

import createTheme from './muiThemeOverrides';
import colors from '../colors';

const GreenTheme = {
  background: {
    main: colors.whiteDark,
    deep: colors.whiteGrey,
    light: colors.white,
    secondary: `linear-gradient(90deg, ${colors.sapphire} 10.86%, ${colors.sapphireLight} 89.92%)`,
  },
  palette: {
    mode: 'light' as PaletteMode,
    primary: {
      main: colors.green,
      light: colors.greenLight,
      dark: colors.greenDown,
      darkLight: colors.blackLight,
      contrastText: colors.white,
    },
    error: {
      main: colors.red,
      light: colors.redLight,
      dark: colors.redBright,
    },
    warn: {
      main: colors.orange,
      light: colors.orangeLight,
    },
    success: {
      main: colors.green,
      light: colors.greenLight,
      dark: colors.greenDown,
    },
    text: {
      primary: colors.blackLight,
      secondary: colors.grayBlue,
      disabled: colors.grayLight,
      primaryBlue: colors.blackBlue,
      contrastText: colors.white,
    },
    buttons: {
      confirm: colors.green,
      remove: colors.redBright,
      cancel: colors.whiteGrey,
      text: {
        primary: {
          text: colors.green,
        },
        secondary: {
          text: colors.grayBlue,
        },
        disabled: {
          text: colors.grayBlue,
        },
      },
      outlined: {
        primary: {
          text: colors.blue,
        },
        secondary: {
          text: colors.blackLight,
          border: colors.grayLight,
          hoverBackground: colors.grayLighter,
        },
        disabled: {
          text: colors.grayBlue,
          background: colors.whiteDark,
          border: 'transparent',
        },
      },
      contained: {
        default: {
          background: colors.white,
          hoverBackground: colors.grayLighter,
        },
        primary: {
          text: colors.white,
          background: colors.green,
          hoverBackground: colors.greenDown,
        },
        secondary: {
          text: colors.white,
          background: colors.red,
          hoverBackground: colors.redBright,
        },
        inherit: {
          text: colors.white,
          background: colors.green,
          hoverBackground: colors.greenDown,
        },
        disabled: {
          text: colors.grayBlue,
          background: colors.blueLight,
          border: 'transparent',
        },
      },
    },
    form: {
      main: colors.grayLight,
      active: colors.green,
      disabled: {
        text: colors.grayBlue,
        background: colors.blueLight,
        border: colors.grayBlue,
      },
    },
    scrollThumb: colors.blueLight,
    paginationHover: colors.blueLight,
    border: colors.grayLight,
  },
};

export default createTheme(GreenTheme);
